<script setup>
import { defineOptions, onMounted, ref, watch } from 'vue'
import Swal from 'sweetalert2'
import { parseString } from 'xml2js'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import ModalAsignarLotes from '../components/modalAsignarLotes.vue'
import ModalCargarLotesDisfarma from '../components/modalCargarLotesDisfarma.vue'
import SidebarItemsPedido from '../components/sidebarItemsPedido.vue'
import ModalValidation from '../components/modalValidation.vue'
import { error, messageConfirmSave } from '../../../../../../../libs/mensajes'
import { useRoute, useRouter } from 'vue-router'
import { helper } from '../../../../../../../utils/helper'
import { menuCollapse } from '@/utils/collapseMenu'
import { useToast } from 'primevue/usetoast'
import Icon from '@/components/Icon/index.vue'
import dayjs from 'dayjs'
// import { useInfoCargueFacturasStore } from '../../../store/infoCargueFacturas.store'

defineOptions({
  name: 'XmlPage'
})

const toast = useToast()
const router = useRouter()
const route = useRoute()
const openSidebarItemsPedido = ref()
const _RecepcionPedidosService = ref(new RecepcionPedidosService())
// const infoStore = useInfoCargueFacturasStore()
const isDragging = ref(false)
const medicamentosList = ref([])
const filterMedicamentosList = ref([])
const allMedicamentosListAgrupaciones = ref([])
const bodegas = ref([])
const filteredBodegas = ref([])
const infoLotes = ref([])
const loadingItems = ref(false)
const modalAsignarLotes = ref()
const modalValidation = ref()
const modalCargueLotesDisfarma = ref()
const op = ref()
const hidePedido = ref(true)
const numPedidos = ref([])
const numPedidosConsultados = ref('')
const selectedFilterSearch = ref(1)
const infoPedidos = ref([])
const newInvoiceItems = ref([])
const disabledButtonPedido = ref(true)
const highlightedRowId = ref(null)
const cumsUnique = ref([])
const cumsAllFactura = ref([])
const cumsNoAgrupExist = ref([])
const proveedorNoCum = ref(false)
const arrTotalLinesInvoice = ref([])
const infoProveedor = ref({})
const copiedCum = ref('')
const moreLinesWithSameBodega = ref(false)
const btnEditLotes = ref(null)
const isValidatePreliminar = ref(false)
const listFilterSearch = ref([
  { label: 'Agrupación', value: 1 },
  { label: 'Agrupación, conversión', value: 2 },
  { label: 'Agrupación, expediente', value: 3 }
])
const existFactura = ref(false)
const existFacturaPg = ref(false)
const Invoice = ref({
  InvoiceNumber: null,
  CardName: null,
  DocDate: null,
  TaxDate: null,
  Comments: null,
  JournalMemo: null,
  SubTotal: null,
  DocTotalBeDi: null,
  DocTotal: null,
  TaxTotal: null,
  DiscTotal: 0,
  Items: []
})
const InvoiceCopy = ref({
  InvoiceNumber: null,
  CardName: null,
  DocDate: null,
  TaxDate: null,
  Comments: null,
  JournalMemo: null,
  SubTotal: null,
  DocTotalBeDi: null,
  DocTotal: null,
  TaxTotal: null,
  DiscTotal: 0,
  Items: []
})
// Proveedores que no se les aplica la conversión
const proveedoresWithoutConversion = ref([
  'OFFIMEDICAS S.A.',
  'Abbott Laboratories de Colombia SAS',
  'GRUPO AFIN FARMACEUTICA SAS',
  'COOPERATIVA DE HOSPITALES DE SANTANDER Y EL NORORIENTE COLOMBIANO'
])

// Funcion para ver el pedido
const viewPedido = () => {
  hidePedido.value = !hidePedido.value
}

// Funcion para buscar los pedidos
const searchPedido = () => {
  // Validar que al menos un pedido esté ingresado
  if (numPedidos.value.length === 0) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'Debe ingresar al menos un número de pedido para buscar',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true
    })
    numPedidos.value = numPedidosConsultados.value.split(',')
    return
  }

  _RecepcionPedidosService.value.getPedidosFactura({
    numPedidos: numPedidos.value,
    nit: Invoice.value.NitCompleto
  }).then(({ data, status }) => {
    console.log('searchPedido data --> ', data)

    // Si viene solo pedidos que pertenecen al proveedor
    if (data.validacionInfo.pedidosPertenecen.length > 0 && data.validacionInfo.pedidosNoExistentesOcerrados.length === 0 && data.validacionInfo.pedidosNoPertenecen.length === 0) {
      Swal.fire({
        icon: 'success',
        title: 'Información',
        html: 'Ok',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          disabledButtonPedido.value = false
          hidePedido.value = false
          infoPedidos.value = data.infoData
          // Agregarle un index incremental a cada item
          infoPedidos.value.forEach((x, index) => {
            x.Index = index + 1
          })

          // Eliminar los pedidos que no pertenecen al proveedor y los que no existen o están cerrados
          numPedidos.value = data.validacionInfo.pedidosPertenecen
          numPedidosConsultados.value = data.validacionInfo.pedidosPertenecen.join(',')

          await fetchAllMdsByCums(cumsUnique.value.join(','))
          await resetItemFacturaInfoItem()
          await detectItemPedido(Invoice.value.Items)

          await buildArrTotalLinesInvoice(Invoice.value.Items)
        }
      })
    } else if (data.validacionInfo.pedidosPertenecen.length > 0 && (data.validacionInfo.pedidosNoExistentesOcerrados.length > 0 || data.validacionInfo.pedidosNoPertenecen.length > 0)) {
      const htmlValidacion = `
        <p class="text-sm">Se cargará la información de los siguientes pedidos:</p>
        <span class="text-green-700 font-semibold text-sm">
          ${data.validacionInfo.pedidosPertenecen.join(',')}
        </span>

        ${data.validacionInfo.pedidosNoExistentesOcerrados.length > 0
          ? ` <p class="mt-2 text-sm">Los siguientes pedidos no existen o se encuentran cerrados:</p>
            <span class="text-red-700 font-semibold text-sm">
              ${data.validacionInfo.pedidosNoExistentesOcerrados.join(',')}
            </span>`
          : ''
        }

        ${data.validacionInfo.pedidosNoPertenecen.length > 0
          ? `<p class="mt-2 text-sm">Los siguientes pedidos no pertenecen al proveedor:</p>
            <span class="text-red-700 font-semibold text-sm">
              ${data.validacionInfo.pedidosNoPertenecen.join(',')}
            </span>`
          : ''
        }
        <p class="mt-4 text-xxs text-gray-600 underline">Por favor, verifique que el número de pedido sea correcto y que el proveedor sea el mismo de la factura.</p>
      `

      Swal.fire({
        icon: 'info',
        title: 'Información',
        html: htmlValidacion,
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          disabledButtonPedido.value = false
          hidePedido.value = false
          infoPedidos.value = data.infoData
          infoPedidos.value.forEach((x, index) => {
            x.Index = index + 1
          })
          // Eliminar los pedidos que no pertenecen al proveedor y los que no existen o están cerrados
          numPedidos.value = data.validacionInfo.pedidosPertenecen
          numPedidosConsultados.value = data.validacionInfo.pedidosPertenecen.join(',')

          await fetchAllMdsByCums(cumsUnique.value.join(','))
          await resetItemFacturaInfoItem()
          await detectItemPedido(Invoice.value.Items)

          await buildArrTotalLinesInvoice(Invoice.value.Items)
        }
      })
    } else if (data.validacionInfo.pedidosNoExistentesOcerrados.length > 0 && (data.validacionInfo.pedidosPertenecen.length === 0 && data.validacionInfo.pedidosNoPertenecen.length === 0)) {
      Swal.fire({
        icon: 'error',
        title: 'Validación',
        html: 'Los pedidos ingresados no existen o ya se encuentran cerrados',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
      })
    } else if (data.validacionInfo.pedidosNoPertenecen.length > 0 && (data.validacionInfo.pedidosPertenecen.length === 0 && data.validacionInfo.pedidosNoExistentesOcerrados.length === 0)) {
      Swal.fire({
        icon: 'error',
        title: 'Validación',
        html: 'Los pedidos ingresados no pertenecen al proveedor',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true
      })
    }
  }).catch((res) => {
    clearPedidos()
    console.log('Error pedido data --> ', res)
  })
}

// Funcion para detectar si el item de la factura está en el pedido (LineNum y DocNum)
const detectItemPedido = (itemFactura) => {
  // Agregar en cada item del pedido una nueva propiedad diciendo si está o no en el pedido
  infoPedidos.value.forEach((itemPedido) => {
    itemPedido.isInPedido = itemFactura.some((itemFactura) => itemFactura.LineNum === itemPedido.LineNum && itemFactura.DocNum === itemPedido.DocNum)
    // Agregar el index de la factura
    const indexFact = itemFactura.findIndex((itemFactura) => itemFactura.LineNum === itemPedido.LineNum && itemFactura.DocNum === itemPedido.DocNum) + 1
    if (itemPedido.isInPedido) {
      itemPedido.IndexFact = indexFact
    } else {
      itemPedido.IndexFact = null
    }
  })
}

// Limpiar pedidos
const clearPedidos = () => {
  numPedidos.value = []
  disabledButtonPedido.value = true
  hidePedido.value = true
  infoPedidos.value = []
  proveedorNoCum.value = false
}

// Funcion para abrir el modal de asignar lotes
const openModalAsignarLotes = (data) => {
  modalAsignarLotes.value.openModal(data)
}

// Funcion para abrir el modal de validacion
const openModalValidation = (data) => {
  modalValidation.value.openModal(data)
}

// Funcion para abrir el modal de cargue lotes disfarma
const openModalCargueLotesDisfarma = (data) => {
  console.log('openModalCargueLotesDisfarma data --> ', data)
  modalCargueLotesDisfarma.value.openModal(data)
}

// Visualizar la info de los lotes
const toggle = (event, data) => {
  infoLotes.value = data.BatchNumbers
  if (op.value) {
    op.value.toggle(event)
  } else {
    toast.add({ severity: 'info', summary: 'Información', detail: 'Para visualizar los lotes dar clic sobre el botón "Editar lotes"', life: 5000 })
  }
}

// Calcular el total de cada línea (items), multiplicando el valor de la unidad por la cantidad
const calcularTotalLinea = (data) => {
  // Convertir Price y InvoiceQuantity a número por si vienen como string
  const price = parseFloat(data.Price)
  const invoiceQuantity = parseFloat(data.InvoiceQuantity)
  const taxPercent = parseFloat(data.TaxPercent) || 0

  // Calcular los totales
  data.TaxTotalCalculado = Math.round(price * invoiceQuantity * taxPercent / 100)
  data.LineTotalCalculado = Math.round(price * invoiceQuantity + (data.TaxTotalCalculado || 0))

  return {
    TaxTotalCalculado: data.TaxTotalCalculado,
    SubTotalCalculado: price * invoiceQuantity,
    LineTotalCalculado: data.LineTotalCalculado
  }
}

// Funcion para asignar la cantidad al lote
const asignarCantidad = (data) => {
  console.log('data asignar cantidad --> ', data)
  data.pedidoQuantity = parseInt(data.InvoiceQuantity)

  data.BatchNumbers.forEach((x) => {
    x.Quantity = data.InvoiceQuantity
  })
}

// Funcion para extraer el lote y la fecha de vencimiento del proveedor OFFIMEDICAS S.A.
const extraerLoteFechaVencimientoOffimedicas = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /Lote\s+(\S+)/
  const venceRegex = /Vence\s+(\d{2}\/\d{2})/
  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)
  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? venceMatch[1] : null
  // Formatear la fecha de vencimiento al formato deseado (YYYY-MM-DD)
  const formattedExpDate = vence ? `20${vence.substr(3, 2)}-${vence.substr(0, 2)}-01` : null
  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Funcion para extraer el lote y la fecha de vencimiento del proveedor EVE DISTRIBUCIONES
const extraerLoteFechaVencimientoEveDistribuciones = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /LOTE:\s+(\S+)/
  const venceRegex = /FECHA VENC:\s+(\d{4})(\d{2})(\d{2})/
  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)
  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? `${venceMatch[1]}${venceMatch[2]}${venceMatch[3]}` : null
  // La fecha viene así 20250530, se debe formatear al formato deseado (YYYY-MM-DD)
  const formattedExpDate = vence ? `${vence.substring(0, 4)}-${vence.substring(4, 6)}-${vence.substring(6, 8)}` : null
  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Funcion para extraer el lote y la fecha de vencimiento del proveedor MAS QUE INSUMOS
const extraerLoteFechaVencimientoMasQueInsumos = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /LOTE\s+([^V.]+)/
  const venceRegex = /V\.(\d{1,2}\.\d{1,2}\.\d{4})/
  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)
  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? venceMatch[1] : null
  // Formatear la fecha al formato YYYY-MM-DD
  const formattedExpDate = vence
    ? vence.split('.').map(part => part.padStart(2, '0')).reverse().join('-')
    : null
  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Función mejorada para extraer el lote y la fecha de vencimiento del proveedor DROGUERÍAS Y FARMACIAS CRUZ VERDE SAS
const extraerLoteFechaVencimientoDrogueriasCruzVerde = (inputArray) => {
  // Filtramos solo las cadenas que comienzan con "CANTIDAD"
  const relevantStrings = inputArray.filter((item) => item.startsWith('CANTIDAD'))

  const result = relevantStrings.map((item) => {
    // Extraemos la cantidad, el lote y la fecha de vencimiento
    const match = item.match(/CANTIDAD (\d*) ?\/ ?([\w-]*)(?: \/ (\d{2}-\d{2}-\d{4}))?/)

    if (match) {
      return {
        Quantity: match[1] ? parseInt(match[1]) : 0, // Si no hay cantidad, asignamos 0
        DistNumber: match[2] || null, // Si no hay número de distribución, asignamos null
        ExpDate: match[3] ? match[3].split('-').reverse().join('-') : null // Si no hay fecha de vencimiento, asignamos null
      }
    } else {
      // Si no coincide con el patrón esperado, retornamos un objeto con valores por defecto
      return {
        Quantity: 0,
        DistNumber: null,
        ExpDate: null
      }
    }
  })

  // Filtramos los elementos que tengan tanto Quantity como DistNumber como valores por defecto
  return result.filter((item) => !(item.Quantity === 0 && item.DistNumber === null))
}

// Función para extraer el lote y la fecha de vencimiento del proveedor XINETIX PHARMA SAS
const extraerLoteFechaVencimientoXinetixPharma = (inputString) => {
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /Lote:\s+(\S+)/
  const venceRegex = /F-Venc:\s+(\d{2}\/\d{2}\/\d{2})/
  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)
  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? venceMatch[1] : null
  // Vence viene asi: AA/MM/DD, se debe formatear al formato deseado (YYYY-MM-DD)
  const formattedExpDate = vence ? `20${vence.substr(0, 2)}-${vence.substr(3, 2)}-${vence.substr(6, 2)}` : null
  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

// Función para extraer el lote y la fecha de vencimiento del proveedor COOPERATIVA DE HOSPITALES DE SANTANDER Y EL NORORIENTE COLOMBIANO
const extraerLoteFechaVencimientoCohosan = (itemInstanceInfo) => {
  const quantities = itemInstanceInfo.find(item => item['cbc:Name'][0] === 'campoAdicionalDet10')['cbc:Value'][0].split('|')
  const distNumbers = itemInstanceInfo.find(item => item['cbc:Name'][0] === 'campoAdicionalDet11')['cbc:Value'][0].split('|')
  const expDates = itemInstanceInfo.find(item => item['cbc:Name'][0] === 'campoAdicionalDet9')['cbc:Value'][0].split('|')

  return quantities.map((quantity, index) => {
    return {
      ExpDate: `${expDates[index].substr(2, 6)}-${expDates[index].substr(0, 2)}-01`,
      DistNumber: distNumbers[index],
      Quantity: parseInt(quantity)
    }
  })
}

// Función para extraer el lote y la fecha de vencimiento del proveedor Megalabs Colombia SAS
const extraerLoteFechaVencimientoMegalabs = (itemInstanceInfo, invoicedQuantity) => {
  const distNumbers = itemInstanceInfo[0]['cbc:Value'][0]
  const expDates = itemInstanceInfo[1]['cbc:Value'][0]

  // expDates viene así: DD/M/YYYY, se debe formatear al formato deseado (YYYY-MM-DD)
  const formattedExpDate = expDates.split('/').reverse().join('-')

  return [{
    ExpDate: formattedExpDate,
    DistNumber: distNumbers,
    Quantity: parseInt(invoicedQuantity[0]._) || 0
  }]
}

// Función para extraer el lote y la fecha de vencimiento del proveedor Pharmaplus S.A.S
const extraerLoteFechaVencimientoPharmaplus = (inputString) => {
  // El inputString viene asi: "ENOXPAR INY 40MG/0.4ML CJ X 2 Invima: 2018M-0006078-R1 (Lote 149323 - FV 30/11/2025) - Cod 7703889150189"
  // Definir expresiones regulares para el lote y la fecha de vencimiento
  const loteRegex = /Lote\s+(\S+)/
  const venceRegex = /FV\s+(\d{2}\/\d{2}\/\d{4})/
  // Buscar coincidencias en la cadena
  const loteMatch = inputString.match(loteRegex)
  const venceMatch = inputString.match(venceRegex)
  // Extraer los valores encontrados
  const lote = loteMatch ? loteMatch[1] : null
  const vence = venceMatch ? venceMatch[1] : null
  // Formatear la fecha de vencimiento al formato deseado (YYYY-MM-DD)
  const formattedExpDate = vence ? `${vence.substr(6, 4)}-${vence.substr(3, 2)}-${vence.substr(0, 2)}` : null
  // Crear y devolver el objeto final
  return {
    DistNumber: lote ? lote.trim() : null,
    ExpDate: formattedExpDate ? formattedExpDate.trim() : null
  }
}

//
const extraerLoteFechaVencimientoLabLimOriente = (invoiceLineInfo) => {
  const infoNote = invoiceLineInfo['cbc:Note'] ? invoiceLineInfo['cbc:Note'][0] : ''

  // Viene así: LOTE # 9464850 F.V 08/11/2025 INVIMA # 2022DM-0008213-R1, obtener el lote y la fecha de vencimiento
  const loteRegex = /LOTE # (\d+) F\.V (\d{2}\/\d{2}\/\d{4})/
  const loteMatch = infoNote.match(loteRegex)

  return {
    DistNumber: loteMatch ? loteMatch[1] : '',
    ExpDate: loteMatch ? `${loteMatch[2].substr(6, 4)}-${loteMatch[2].substr(3, 2)}-${loteMatch[2].substr(0, 2)}` : ''
  }
}

// Funcion para obtener la información de los lotes
const getBatchInfo = (proveedorInfo, description, invoicedQuantity, priceBaseQuantity, itemInstance, invoiceLine) => {
  if (proveedorInfo.nombre === 'OFFIMEDICAS S.A.') {
    const infoLotes = extraerLoteFechaVencimientoOffimedicas(description[0].trim())
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedorInfo.nombre === 'MAS QUE INSUMOS  SAS') {
    const infoLotes = extraerLoteFechaVencimientoMasQueInsumos(description[0].trim())
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedorInfo.nombre === 'EVE DISTRIBUCIONES S.A.S') {
    const infoLotes = extraerLoteFechaVencimientoEveDistribuciones(description[0])
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedorInfo.nit === '800149695' || proveedorInfo.nombre === 'DROGUERÍAS Y FARMACIAS CRUZ VERDE SAS.') {
    console.log('Extraer lotes cruz verde --> ', extraerLoteFechaVencimientoDrogueriasCruzVerde(description, invoicedQuantity))
    return extraerLoteFechaVencimientoDrogueriasCruzVerde(description, invoicedQuantity)
  } else if (proveedorInfo.nit === '830500960' || proveedorInfo.nombre === 'INVERSIONES TODO DROGAS S.A.S.') {
    return [{
      ExpDate: '',
      DistNumber: '',
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedorInfo.nit === '900710720' || proveedorInfo.nombre === 'XINETIX PHARMA SAS') {
    const infoLotes = extraerLoteFechaVencimientoXinetixPharma(description[0].trim())
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedorInfo.nit === '804009200' || proveedorInfo.nombre === 'COOPERATIVA DE HOSPITALES DE SANTANDER Y EL NORORIENTE COLOMBIANO') {
    const itemInstanceInfo = itemInstance['cac:AdditionalItemProperty'] ? itemInstance['cac:AdditionalItemProperty'] : []
    return extraerLoteFechaVencimientoCohosan(itemInstanceInfo)
  } else if (proveedorInfo.nit === '800133807-1' || proveedorInfo.nombre === 'Megalabs Colombia SAS') {
    const itemInstanceInfo = itemInstance['cac:AdditionalItemProperty'] ? itemInstance['cac:AdditionalItemProperty'] : []
    return extraerLoteFechaVencimientoMegalabs(itemInstanceInfo, invoicedQuantity)
  } else if (proveedorInfo.nit === '830110109-7' || proveedorInfo.nombre === 'PHARMAPLUS S.A.S') {
    const infoLotes = extraerLoteFechaVencimientoPharmaplus(description[0].trim())
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else if (proveedorInfo.nit === '901298922-3' || proveedorInfo.nombre === 'LABORATORIOS LIMITADA DEL ORIENTE S.A.S.') {
    const infoLotes = extraerLoteFechaVencimientoLabLimOriente(invoiceLine)
    return [{
      ExpDate: infoLotes.ExpDate,
      DistNumber: infoLotes.DistNumber,
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  } else {
    return itemInstance['cac:ItemInstance'] ? itemInstance['cac:ItemInstance'][0]['cac:LotIdentification'].map((l) => {
      return {
        ExpDate: l['cbc:ExpiryDate'][0] || '',
        DistNumber: l['cbc:LotNumberID'][0] || '',
        Quantity: parseInt(priceBaseQuantity[0]._) || 0
      }
    }) : [{
      ExpDate: '',
      DistNumber: '',
      Quantity: parseInt(invoicedQuantity[0]._) || 0
    }]
  }
}

// Función para extraer el CUM del proveedor offimedicas
const getCumOffimedicas = (description) => {
  const cumRegex = /Cum\s+\(\s+(\d{8}-\d{2})\s+\)/
  const cumMatch = description[0].match(cumRegex)

  // Quitar ceros
  if (cumMatch) {
    const cum = cumMatch[1].split('-')
    return `${parseInt(cum[0])}-${parseInt(cum[1])}`
  } else {
    return ''
  }
}

// Función para extraer el laboratorio del proveedor offimedicas
const getLaboratorioOffimedicas = (description) => {
  const laboratorioRegex = /\s+(\w+)\s+Cum\s+/
  const laboratorioMatch = description[0].match(laboratorioRegex)

  return laboratorioMatch ? laboratorioMatch[1] : ''
}

// Función para extraer el CUM del proveedor droguerías y farmacias cruz verde sas
const getCumDrogueriasCruzVerde = (description) => {
  const cumRegex = /CUM:(\d{8}-\d{2})/
  const cumRegex2 = /(\d{6}-\d{1})/
  const cumMatch = description[0].match(cumRegex) || description[0].match(cumRegex2)

  // Quitar ceros
  if (cumMatch) {
    const cum = cumMatch[1].split('-')
    return `${parseInt(cum[0])}-${parseInt(cum[1])}`
  } else {
    return ''
  }
}

// Función para extraer el CUM del proveedor eve distribuciones
const getCumEveDistribuciones = (description) => {
  const cumRegex = /CUM: (\d{8}-\d{2})/
  const cumMatch = description[0].match(cumRegex)

  // Quitar ceros
  if (cumMatch) {
    const cum = cumMatch[1].split('-')
    return `${parseInt(cum[0])}-${parseInt(cum[1])}`
  } else {
    return ''
  }
}

// Función para extraer el CUM del proveedor inversiones TODODrogas s.a.s
const getCumTodoDrogas = (description) => {
  const cumRegex = /CUM:(\d{8}-\d+)/
  const cumMatch = description[0].match(cumRegex)

  // Quitar ceros
  if (cumMatch) {
    const cum = cumMatch[1].split('-')
    return `${parseInt(cum[0])}-${parseInt(cum[1])}`
  } else {
    return ''
  }
}

// Función para extraer el CUM del proveedor xinetix pharma sas
const getCumXinetixPharma = (description) => {
  const cumRegex = /CUM:(\d+-\d+)/
  const cumMatch = description[0].match(cumRegex)

  // Quitar ceros
  if (cumMatch) {
    const cum = cumMatch[1].split('-')
    return `${parseInt(cum[0])}-${parseInt(cum[1])}`
  } else {
    return ''
  }
}

// Función para extraer el CUM del proveedor COHOSAN
const getCumCohosan = (description) => {
  const cumRegex = /CUM:(\d+-\d+)/
  const cumMatch = description[0].match(cumRegex)

  // Quitar ceros
  if (cumMatch) {
    const cum = cumMatch[1].split('-')
    return `${parseInt(cum[0])}-${parseInt(cum[1])}`
  } else {
    return ''
  }
}

// Obtener la presentacion, laboratorio y cums dependiendo del proveedor
const getValuesByProveedor = (value, description, proveedor, tipo) => {
  if (proveedor === 'DISFARMA GC SAS' && tipo === 'cum') {
    return value // Devuelve el CUM sin modificar para Disfarma
  } else if (proveedor === 'OFFIMEDICAS S.A.') {
    if (tipo === 'cum') {
      return getCumOffimedicas(description)
    } else if (tipo === 'laboratorio') {
      return getLaboratorioOffimedicas(description)
    }
  } else if (proveedor === 'DROGUERÍAS Y FARMACIAS CRUZ VERDE SAS.') {
    if (tipo === 'cum') {
      return getCumDrogueriasCruzVerde(description)
    }
  } else if (proveedor === 'EVE DISTRIBUCIONES S.A.S') {
    if (tipo === 'cum') {
      return getCumEveDistribuciones(description)
    }
  } else if (proveedor === 'INVERSIONES TODO DROGAS S.A.S.') {
    if (tipo === 'cum') {
      return getCumTodoDrogas(description)
    }
  } else if (proveedor === 'XINETIX PHARMA SAS') {
    if (tipo === 'cum') {
      return getCumXinetixPharma(description)
    }
  } else if (proveedor === 'COOPERATIVA DE HOSPITALES DE SANTANDER Y EL NORORIENTE COLOMBIANO') {
    if (tipo === 'cum') {
      return getCumCohosan(description)
    }
  } else {
    return value
  }
}

// Función para obtener los CUMs de Disfarma
const getHomologacionDisfarma = async (codigosDisfarma) => {
  try {
    const batchSize = 50 // Se procesan de a 50 items
    const results = []

    for (let i = 0; i < codigosDisfarma.length; i += batchSize) {
      const batch = codigosDisfarma.slice(i, i + batchSize)
      const response = await _RecepcionPedidosService.value.getCumsDisfarma({
        codigosDisfarma: batch
      })
      results.push(...(response.data.getCumDisfarma || []))
    }

    const mapa = results.reduce((acc, item) => {
      acc[item.codigoDisfarma] = item.cum
      return acc
    }, {})

    console.log('Mapa de CUMs Disfarma:', mapa)
    return mapa
  } catch (error) {
    console.error('Error al obtener CUMs de Disfarma:', error)
    return {}
  }
}

// Funcion para generar el objeto de los items
const generarObjItems = (x, index, BatchLotes, proveedor, cumsDisfarmaMapa = {}) => {
  console.log('proveedor --> ', proveedor)
  let cums = ''

  if (proveedor === 'DISFARMA GC SAS') {
    const codigoDisfarma =
      x['cac:Item'][0]['cac:StandardItemIdentification'][0]['cbc:ID'][0]._ ?? ''
    cums = cumsDisfarmaMapa[codigoDisfarma] || ''
    console.log(`Item ${index + 1} - Código Disfarma: ${codigoDisfarma}, CUM asignado: ${cums}`)
  } else {
    switch (proveedor) {
      case 'MAS QUE INSUMOS  SAS':
        cums = x['cbc:Note'] ? x['cbc:Note'][0] : ''
        break
      case 'Abbott Laboratories de Colombia SAS':
        cums = ''
        break
      case 'Megalabs Colombia SAS':
        cums = x['cac:Item'][0]['cac:AdditionalItemProperty'][2]['cbc:Value'][0]
        break
      default:
        cums = x['cbc:Note'] ? x['cbc:Note'][0]?.split('|')[2] || '' : ''
        break
    }
  }

  const presentacion = x['cbc:Note'] ? x['cbc:Note'][0]?.split('|')[1] || '' : ''
  const laboratorio = x['cbc:Note'] ? x['cbc:Note'][0]?.split('|')[3] || '' : ''

  return {
    Index: index + 1,
    IndexFact: null,
    IndexPedido: null,
    IsLineOriginalFact: true,
    Description: x['cac:Item'][0]['cbc:Description'][0].trim(),
    IdentificationItem: x['cac:Item'][0]['cbc:Description'][0].trim(),
    CodCum:
      cums || getValuesByProveedor(cums, x['cac:Item'][0]['cbc:Description'], proveedor, 'cum'),
    Presentacion: presentacion,
    PresentacionSap: '',
    Laboratorio: getValuesByProveedor(
      laboratorio,
      x['cac:Item'][0]['cbc:Description'],
      proveedor,
      'laboratorio'
    ),
    LaboratorioSap: '',
    BatchNumbers: BatchLotes,
    InvoiceQuantity: parseInt(x['cbc:InvoicedQuantity'][0]._) || 0,
    InvoiceQuantityInicial: parseInt(x['cbc:InvoicedQuantity'][0]._) || 0,
    Price: parseInt(x['cac:Price'][0]['cbc:PriceAmount'][0]._) || 0,
    PriceInicial: parseInt(x['cac:Price'][0]['cbc:PriceAmount'][0]._) || 0,
    LineTotal: parseInt(x['cbc:LineExtensionAmount'][0]._) || 0,
    LineTotalCalculado: 0,
    InfoItem: null,
    InfoBodega: null,
    LineNum: null,
    DocNum: null,
    DocEntryPedido: null,
    ObjTypePedido: null,
    IdAgrupacion: '',
    Agrupacion: '',
    frozenFor: '',
    TaxPercent: x['cac:TaxTotal']
      ? parseInt(x['cac:TaxTotal'][0]['cac:TaxSubtotal'][0]['cac:TaxCategory'][0]['cbc:Percent'][0])
      : 0,
    TaxTotal: x['cac:TaxTotal']
      ? parseInt(x['cac:TaxTotal'][0]['cac:TaxSubtotal'][0]['cbc:TaxAmount'][0]._)
      : 0,
    TaxTotalCalculado: 0,
    TaxCodeAP: '',
    // Agregar CodigoDisfarma si el proveedor es Disfarma
    CodigoDisfarma:
      proveedor === 'DISFARMA GC SAS'
        ? x['cac:Item'][0]['cac:StandardItemIdentification'][0]['cbc:ID'][0]._
        : ''
  }
}

// Construir arrTotalLinesInvoice con descripcion, el cum, el total de la línea inicial y calculado
const buildArrTotalLinesInvoice = (items) => {
  arrTotalLinesInvoice.value = items.map((x, index) => {
    return {
      Description: x.Description.trim(),
      CodCum: x.CodCum,
      TotalLineCalculado: parseInt(x.LineTotalCalculado),
      SubTotal: parseInt(x.LineTotal),
      TotalLine: parseInt(x.LineTotal) + parseInt(x.TaxTotal),
      Quantity: parseInt(x.InvoiceQuantityInicial),
      Conversion: x.Conversion ? parseInt(x.Conversion) : null,
      QuantityWithConversion: x.Conversion ? parseInt(x.InvoiceQuantityInicial) * parseInt(x.Conversion) : null,
      IndexFact: x.IndexFact,
      IndexPedido: x.IndexPedido,
      InvoiceQuantity: x.InvoiceQuantity,
      IsUnique: x.IsUnique,
      IsLineOriginalFact: x.IsLineOriginalFact,
      BodegaMxAsignado: x.InfoBodega ? x.InfoBodega.bodega : null,
      ItemCodeMxAsignado: x.InfoItem ? x.InfoItem.ItemCode : null,
      ItemNameMxAsignado: x.InfoItem ? x.InfoItem.ItemName : null,
      ConversionMxAsignado: x.InfoItem ? x.InfoItem.Conversion : null,
      CumMxAsignado: x.InfoItem ? x.InfoItem.Cum : null,
      IsSustituto: x.IsSustituto
    }
  })
}

// Si se actualiza el LineTotalCalculado de la línea con el CodCum, se actualiza el arrTotalLinesInvoice el valor de TotalLineCalculado
watch(() => Invoice.value.Items, (newVal, oldVal) => {
  newVal.forEach((item) => {
    // Filtrar el item en el arrTotalLinesInvoice para cambiar el valor de TotalLineCalculado
    const itemInArrayTotalLines = arrTotalLinesInvoice.value.find((x) => x.CodCum === item.CodCum && x.IndexFact === item.IndexFact)

    if (itemInArrayTotalLines) {
      itemInArrayTotalLines.TotalLineCalculado = item.LineTotalCalculado
      itemInArrayTotalLines.TaxTotal = item.TaxTotal
      itemInArrayTotalLines.TaxTotalCalculado = item.TaxTotalCalculado
      itemInArrayTotalLines.TaxPercent = item.TaxPercent
      itemInArrayTotalLines.InvoiceQuantity = item.InvoiceQuantity
      itemInArrayTotalLines.ConversionMxAsignado = item.InfoItem ? item.InfoItem.Conversion : null
      itemInArrayTotalLines.ItemNameMxAsignado = item.InfoItem ? item.InfoItem.ItemName : null
      itemInArrayTotalLines.ItemCodeMxAsignado = item.InfoItem ? item.InfoItem.ItemCode : null
      itemInArrayTotalLines.CumMxAsignado = item.InfoItem ? item.InfoItem.Cum : null
      itemInArrayTotalLines.BodegaMxAsignado = item.InfoBodega ? item.InfoBodega.bodega : null
      itemInArrayTotalLines.IsSustituto = item.IsSustituto
      itemInArrayTotalLines.IndexPedido = item.IndexPedido
    }
  })
}, { deep: true })

/**
 * Funcion para asignar la data del xml al objeto
 * @param file
 * @return {Promise<void>}
 */
const handleFileUpload = async (file) => {
  const reader = new FileReader()

  reader.onload = async () => {
    await processXmlContent(reader.result)
  }

  reader.readAsText(file)
}

/**
 * Función para procesar el contenido del archivo XML
 * @param xmlContent
 * @return {Promise<void>}
 */
const processXmlContent = async (xmlContent) => {
  const result = convertXmlToJson(xmlContent)
  if (!result || !result.AttachedDocument) {
    await Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'El archivo ingresado no es XML válido para facturación electrónica por favor valide e inténtelo nuevamente',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })
    return
  }
  const xmlDescription = convertXmlToJson(
    result.AttachedDocument['cac:Attachment'][0]['cac:ExternalReference'][0]['cbc:Description']
  )
  console.log('xmlDescription --> ', xmlDescription)
  // Invoice.value.PedidoReferencia = xmlDescription.Invoice['cac:OrderReference'][0]['cbc:ID'][1]

  const proveedor =
    xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0][
      'cac:PartyLegalEntity'
      ][0]['cbc:RegistrationName'][0]
  const proveedorNit =
    xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0][
      'cac:PartyTaxScheme'
      ][0]['cbc:CompanyID'][0]._

  const proveedorInfo = {
    nombre: proveedor,
    nit: proveedorNit
  }

  // Optimización para Disfarma
  let cumsDisfarmaMapa = {}
  if (proveedorInfo.nombre === 'DISFARMA GC SAS') {
    const codigosDisfarma = xmlDescription.Invoice['cac:InvoiceLine'].map(
      (x) => x['cac:Item'][0]['cac:StandardItemIdentification'][0]['cbc:ID'][0]._ ?? ''
    )
    console.log('Códigos Disfarma:', codigosDisfarma)
    cumsDisfarmaMapa = await getHomologacionDisfarma(codigosDisfarma)
    console.log('Mapa de CUMs Disfarma:', cumsDisfarmaMapa)
  }

  // Extraer los CUMs de los items de la factura
  cumsAllFactura.value = xmlDescription.Invoice['cac:InvoiceLine'].map((x) => {
    return getValuesByProveedor(
      x['cbc:Note'] ? x['cbc:Note'][0].split('|')[2] ?? 0 : '',
      x['cac:Item'][0]['cbc:Description'],
      proveedor,
      'cum'
    )
  })

  Invoice.value.Items = xmlDescription.Invoice['cac:InvoiceLine'].map((x, index) => {
    const BatchLotes = getBatchInfo(
      proveedorInfo,
      x['cac:Item'][0]['cbc:Description'],
      x['cbc:InvoicedQuantity'],
      x['cac:Price'][0]['cbc:BaseQuantity'],
      x['cac:Item'][0],
      x
    )

    const item = generarObjItems(x, index, BatchLotes, proveedorInfo.nombre, cumsDisfarmaMapa)
    console.log(`Item ${index + 1} generado:`, {
      CodCum: item.CodCum,
      Description: item.Description
    })
    return item
  })

  console.log('Todos los items generados:', Invoice.value.Items)

  // Se ordena alfabéticamente los items de la factura del proveedor DISFARMA GC SAS
  if (proveedorInfo.nombre === 'DISFARMA GC SAS') {
    Invoice.value.Items.sort((a, b) =>
      a.Description.split('-')[1].localeCompare(b.Description.split('-')[1])
    )
  }

  Invoice.value.InvoiceNumber = xmlDescription.Invoice['cbc:ID'][0]
  Invoice.value.DocDate = xmlDescription.Invoice['cbc:IssueDate'][0]
  Invoice.value.TaxDate = dayjs().format('YYYY-MM-DD')
  Invoice.value.SubTotal = parseInt(
    xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:LineExtensionAmount'][0]._
  )
  Invoice.value.DocTotalBeDi = parseInt(
    xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:LineExtensionAmount'][0]._
  )
  Invoice.value.DocTotal = parseInt(
    xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:PayableAmount'][0]._
  )
  Invoice.value.TaxTotal = xmlDescription.Invoice['cac:TaxTotal']
    ? parseInt(xmlDescription.Invoice['cac:TaxTotal'][0]['cbc:TaxAmount'][0]._)
    : 0
  Invoice.value.CardName = proveedor
  Invoice.value.Nit =
    xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0][
      'cac:PartyTaxScheme'
      ][0]['cbc:CompanyID'][0]._ ?? 0
  Invoice.value.consecutivoNit =
    xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0][
      'cac:PartyTaxScheme'
      ][0]['cbc:CompanyID'][0].$.schemeID ?? 0
  Invoice.value.NitCompleto = Invoice.value.Nit + '-' + Invoice.value.consecutivoNit
  Invoice.value.Tipo = 'Manual'
  Invoice.value.TipoLotes = 'Manual'
  Invoice.value.JournalMemo = `Factura N° ${Invoice.value.InvoiceNumber} ${Invoice.value.CardName}`

  // Validar si ya existe la factura, detener el proceso
  await checkExistFactura(Invoice.value.InvoiceNumber)
  if (existFacturaPg.value) {
    Swal.fire({
      icon: 'warning',
      title: 'Validación',
      text: `La factura #${Invoice.value.InvoiceNumber} del proveedor ${proveedor} se encuentra en proceso de envío a SAP, por favor verifique e intente de nuevo.`,
      allowOutsideClick: false
    }).then(() => {
      // Reload page
      location.reload()
    })
  } else {
    infoProveedor.value = {
      nombre: proveedor,
      nit: Invoice.value.NitCompleto
    }
    console.log('infoProveedor --> ', infoProveedor.value)

    // Obtener los CodigoCum de los items de la factura y quitar los duplicados
    const cums = Invoice.value.Items.map((x) => x.CodCum)
    console.log('CUMs de los items:', cums)
    console.log(
      'Items de la factura:',
      Invoice.value.Items.map((item) => ({ CodCum: item.CodCum, Description: item.Description }))
    )
    cumsUnique.value = [...new Set(cums)]
    console.log('CUMs únicos:', cumsUnique.value)

    // Obtener los medicamentos por los cums de los items de la factura
    await fetchAllMdsByCums(cumsUnique.value.join(',')).then(() => {
      if (proveedorNoCum.value) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          html: `
              <p class="text-sm">En el XML de la factura <strong># ${Invoice.value.InvoiceNumber}</strong> del proveedor <strong>${proveedor}</strong> no se encontraron CUMs.</p>
              <p class="text-sm">Debido a lo anterior, es necesario que el registro de la información se realice de forma manual.</p>
            `
        })
      }
    })
    await setCumsNoAgrupExist()

    // Agrupar los items de la factura por batch
    const itemsAgrupados = agruparItemsPorBatch(Invoice.value.Items)

    // Realizar una copia de Invoice a InvoiceCopy con el fin de que no se altere la información original y agregar el valor de los items agrupados
    InvoiceCopy.value = { ...Invoice.value }
    InvoiceCopy.value.Items = itemsAgrupados

    // Validar si el item es unico dentro de Invoice.value.items
    InvoiceCopy.value.Items.forEach((item) => {
      item.IsUnique =
        InvoiceCopy.value.Items.filter((x) => x.Description === item.Description).length === 1
    })
  }
}

/* Funcion para convertir XML a Json */
const convertXmlToJson = (xmlContent) => {
  let resultado = ''
  parseString(xmlContent, (error, result) => {
    if (error) {
      resultado = ('Error al convertir XML a JSON:', error)
    } else {
      resultado = result
    }
  })
  return resultado
}

/* Funcion para copiar al portapapeles */
const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text)
    copiedCum.value = text
    setTimeout(() => {
      copiedCum.value = ''
    }, 1000)
  } catch (err) {
    console.error('Error in copying text: ', err)
  }
}

/* Funcion para manejar el drag enter */
const dragOver = (event) => {
  event.preventDefault()
  isDragging.value = true
}

/* Funcion para manejar el drag leave */
const dragLeave = (event) => {
  event.preventDefault()
  isDragging.value = false
}

/* Funcion para manejar el drop del archivo */
const handleDrop = (event) => {
  event.preventDefault()
  isDragging.value = false
  const file = event.dataTransfer.files[0]
  if (file.type === 'text/xml') {
    handleFileUpload(file)
  } else {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'El archivo ingresado no es de tipo XML por favor valide e inténtelo nuevamente',
      showConfirmButton: false,
      timer: 3500,
      timerProgressBar: true
    })
  }
}

/* Funcion para obtener las bodegas */
const getBodegas = async () => {
  await _RecepcionPedidosService.value.getBodegas().then(({ data }) => {
    bodegas.value = data
  }).catch((err) => {
    console.error(err.message)
  })
}

/* Funcion para buscar las bodegas */
const searchBodegas = async (event) => {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredBodegas.value = [...bodegas.value]
    } else {
      filteredBodegas.value = bodegas.value.filter((bodega) => {
        // Buscar por bodega o codigo
        return bodega.bodega.toUpperCase().includes(event.query.toUpperCase())
      })
    }
  }, 250)
}

/* Cuando cambia la bodega */
const onChangeBodega = (event, data) => {
  console.log('onChangeBodega data --> ', data)
  const mxInPedido = infoPedidos.value.filter((x) => {
    if (data.InfoItem && data.InfoItem.Cum && data.InfoBodega && data.InfoBodega.WhsCode) {
      return x.IdAgrupacion === data.InfoItem.IdAgrupacion && x.WhsCode === data.InfoBodega.WhsCode // && !x.isInPedido
    }
  })
  console.log('onChangeBodega mxInPedido --> ', mxInPedido)
  if (mxInPedido.length > 1) {
    console.log('onChangeBodega mas de 1 result...')
    showSidebarItemsPedido(mxInPedido, data)
    moreLinesWithSameBodega.value = true
  } else if (mxInPedido.length === 1) {
    console.log('onChangeBodega 1 result...')
    // Ahora se debe corroborar que no exista otro item en pedido con el mismo IndexFact
    const mxInPedidoTrue = infoPedidos.value.find((x) => x.IndexFact === data.IndexFact)
    if (mxInPedidoTrue) {
      mxInPedidoTrue.isInPedido = false
      mxInPedidoTrue.IndexFact = null
    }

    // Agregar isInPedido como true en el item seleccionado validando que exista y no se undefined isInPedido
    const mxPedidoFind = mxInPedido.find((x) => x.isInPedido === false)
    if (mxPedidoFind) {
      mxPedidoFind.isInPedido = true
      mxPedidoFind.IndexFact = data.IndexFact
    }

    Object.assign(data, {
      IsSustituto: mxInPedido[0].Cum !== data.InfoItem.Cum,
      IndexPedido: mxInPedido[0].Index,
      LineNum: mxInPedido[0].LineNum,
      DocNum: mxInPedido[0].DocNum,
      ItemCode: mxInPedido[0].ItemCode,
      ItemName: mxInPedido[0].ItemName,
      DocEntryPedido: mxInPedido[0].DocEntry,
      ObjTypePedido: mxInPedido[0].ObjType,
      Price: calculatePrice(data, infoProveedor.value.nombre, proveedoresWithoutConversion.value),
      InvoiceQuantity: proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre)
        ? parseInt(data.InvoiceQuantityInicial)
        : parseInt(mxInPedido[0].Quantity),
      pedidoQuantity: parseInt(mxInPedido[0].Quantity),
      pedidoQuantityOriginal: parseInt(mxInPedido[0].Quantity),
      BatchNumbers: data.BatchNumbers.map((x) => {
        return { ...x, Quantity: proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre) ? parseInt(data.InvoiceQuantityInicial) : parseInt(mxInPedido[0].Quantity) }
      })
    })
  } else {
    console.log('onChangeBodega 0 result...')
    const mxInPedidoFalse = infoPedidos.value.find((x) => x.Index === data.IndexPedido)
    if (mxInPedidoFalse) {
      mxInPedidoFalse.isInPedido = false
      mxInPedidoFalse.IndexFact = null
    }

    Object.assign(data, {
      IsSustituto: false,
      IndexPedido: null,
      LineNum: null,
      DocNum: null,
      ItemCode: null,
      ItemName: null,
      DocEntryPedido: null,
      ObjTypePedido: null,
      Price: calculatePrice(data, infoProveedor.value.nombre, proveedoresWithoutConversion.value),
      InvoiceQuantity: proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre)
        ? parseInt(data.InvoiceQuantityInicial)
        : parseInt(data.InvoiceQuantityInicial) * (parseInt(data.InfoItem?.Conversion) || 1),
      pedidoQuantity: null,
      pedidoQuantityOriginal: null,
      BatchNumbers: data.BatchNumbers.map((x) => {
        return { ...x, Quantity: parseInt(data.InvoiceQuantity) }
      })
    })
  }
}

// Función auxiliar para calcular el precio
const calculatePrice = (data, infoProveedorNombre, proveedoresWithoutConversion) => {
  if (proveedoresWithoutConversion.includes(infoProveedorNombre)) {
    return parseInt(data.LineTotal) / parseInt(data.InvoiceQuantityInicial || 1)
  } else {
    const conversion = parseInt(data.InfoItem?.Conversion) || 1
    return parseFloat(parseInt(data.PriceInicial || 0) / conversion)
  }
}

/* Funcion cuando se selecciona la bodega */
const selectBodega = (event, data) => {
  // Realizar el evento de clic sobre el boton de editar lotes de la línea
  if (!moreLinesWithSameBodega.value) {
    openModalAsignarLotes(data)
  }
}

/* Actualizar la línea de la factura */
const updateLineFactura = (data) => {
  const selectLinePedido = data.selectedProduct
  console.log('updateLineFactura selectLinePedido --> ', selectLinePedido)
  const selectLineFactura = data.dataLineFactura

  // Data es la línea del pedido, se debe buscar sobre infoPedidos y cambiar el isInPedido a true
  const mxInPedido = infoPedidos.value.find((x) => x.Index === selectLinePedido.Index)
  if (mxInPedido) {
    // Ahora se debe corroborar que no exista otro item en pedido con el mismo IndexFact
    const mxInPedidoTrue = infoPedidos.value.find((x) => x.IndexFact === selectLineFactura.IndexFact)
    if (mxInPedidoTrue) {
      mxInPedidoTrue.isInPedido = false
      mxInPedidoTrue.IndexFact = null
    }

    mxInPedido.isInPedido = true
    mxInPedido.IndexFact = selectLineFactura.IndexFact
  }

  // Buscar la línea en Invoice y cambiar el IndexPedido, LineNum, DocNum, ItemCode, DocEntryPedido, ObjTypePedido
  const mxInFactura = Invoice.value.Items.find((x) => x.IndexFact === selectLineFactura.IndexFact)

  if (mxInFactura) {
    mxInFactura.IsSustituto = selectLinePedido.Cum !== selectLineFactura.InfoItem.Cum
    mxInFactura.IndexPedido = selectLinePedido.Index
    mxInFactura.LineNum = selectLinePedido.LineNum
    mxInFactura.DocNum = selectLinePedido.DocNum
    mxInFactura.ItemCode = selectLinePedido.ItemCode
    mxInFactura.ItemName = selectLinePedido.ItemName
    mxInFactura.DocEntryPedido = selectLinePedido.DocEntry
    mxInFactura.ObjTypePedido = selectLinePedido.ObjType
    mxInFactura.Price = proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre) ? parseInt(mxInFactura.LineTotal) / parseInt(mxInFactura.InvoiceQuantityInicial) : parseInt(mxInFactura.PriceInicial) / parseInt(selectLinePedido.Conversion)
    mxInFactura.InvoiceQuantity = parseInt(selectLinePedido.Quantity)
    mxInFactura.pedidoQuantity = parseInt(selectLinePedido.Quantity)
    mxInFactura.pedidoQuantityOriginal = parseInt(selectLinePedido.Quantity)
    mxInFactura.BatchNumbers = mxInFactura.BatchNumbers.map((x) => {
      return { ...x, Quantity: parseInt(selectLinePedido.Quantity) }
    })
  }

  moreLinesWithSameBodega.value = false
  openModalAsignarLotes(mxInFactura)
}

/* Cancelar la línea de la factura */
const cancelLineFactura = (data) => {
  if (data.isCancel) {
    const selectLineFactura = data.dataLineFactura
    const mxInFactura = Invoice.value.Items.find((x) => x.IndexFact === selectLineFactura.IndexFact)
    if (mxInFactura) {
      mxInFactura.IndexPedido = null
      mxInFactura.LineNum = null
      mxInFactura.DocNum = null
      mxInFactura.ItemCode = null
      mxInFactura.ItemName = null
      mxInFactura.DocEntryPedido = null
      mxInFactura.ObjTypePedido = null
      mxInFactura.InfoItem = null
      mxInFactura.InfoBodega = null
      mxInFactura.Price = parseInt(mxInFactura.PriceInicial)
      mxInFactura.InvoiceQuantity = parseInt(mxInFactura.InvoiceQuantityInicial)
      mxInFactura.BatchNumbers = mxInFactura.BatchNumbers.map((x) => {
        return { ...x, Quantity: parseInt(mxInFactura.InvoiceQuantity) }
      })
    }
  }
}

/* Funcion para verificar si existe ya la factura */
const checkExistFactura = async (numFactura) => {
  // Validar si ya existe la factura, detener el proceso y mostrar mensaje
  await _RecepcionPedidosService.value.checkExistFactura({ numFactura }).then(({ data }) => {
    existFactura.value = !!data.existFactura
    existFacturaPg.value = data.existFacturaPg
  }).catch((err) => {
    console.error(err.message)
  })
}

/* Funcion para obtener los medicamentos por los cums de los items de la factura */
const fetchAllMdsByCums = async (cums) => {
  console.log('fetchAllMdsByCums --> ', cums)
  await _RecepcionPedidosService.value.getAllMdsByCum({ cums }).then(({ data }) => {
    if (data.typeError === 'NotFoundCum') { proveedorNoCum.value = true }
    loadingItems.value = false
    allMedicamentosListAgrupaciones.value = data.listMdsByAgrupaciones

    // Agregar a los items de la factura el campo de IdAgrupacion, Agrupacion, frozenFor, Expediente, Conversion
    Invoice.value.Items.forEach((itemFactura) => {
      const filterMx = allMedicamentosListAgrupaciones.value.filter((mx) => mx.Cum === itemFactura.CodCum)

      itemFactura.IdAgrupacion = filterMx.length > 0 ? filterMx[0].IdAgrupacion : null
      itemFactura.Agrupacion = filterMx.length > 0 ? filterMx[0].Agrupacion : null
      itemFactura.frozenFor = filterMx.length > 0 ? filterMx[0].frozenFor : null
      itemFactura.Expediente = filterMx.length > 0 ? filterMx[0].Expediente : null
      itemFactura.Conversion = filterMx.length > 0 ? filterMx[0].Conversion : null
      itemFactura.PresentacionSap = filterMx.length > 0 ? filterMx[0].Presentacion : null
      itemFactura.LaboratorioSap = filterMx.length > 0 ? filterMx[0].Laboratorio : null
      itemFactura.ItemCode = filterMx.length > 0 ? filterMx[0].ItemCode : null
      itemFactura.ItemName = filterMx.length > 0 ? filterMx[0].ItemName : null
      itemFactura.TaxCodeAP = filterMx.length > 0 ? filterMx[0].TaxCodeAP : null
    })
  }).catch((err) => {
    console.error(err.message)
  })
}

/* Funcion para resetear la información de itemFactura.InfoItem */
const resetItemFacturaInfoItem = async () => {
  // Limpiar la información de itemFactura.InfoItem
  Invoice.value.Items.forEach((itemFactura) => {
    itemFactura.InfoItem = null
    itemFactura.InfoBodega = null
    itemFactura.InvoiceQuantity = 0
  })

  // Limpiar la información de newInvoiceItems
  newInvoiceItems.value = []

  await distribucionBodegasArticuloPedidoFactura()
}

/* Encuentra un elemento con base en el filtro proporcionado */
const findItemByFilter = (filter, itemPedido, itemFactura) => {
  switch (filter) {
    case 1:
      return !['', null, undefined].includes(itemPedido.IdAgrupacion) &&
        itemPedido.IdAgrupacion === itemFactura.IdAgrupacion &&
        itemPedido.frozenFor === 'N'
    case 2:
      return !['', null, undefined].includes(itemPedido.IdAgrupacion) &&
        itemPedido.IdAgrupacion === itemFactura.IdAgrupacion &&
        itemPedido.Conversion === itemFactura.Conversion &&
        itemPedido.frozenFor === 'N'
    case 3:
      return !['', null, undefined].includes(itemPedido.IdAgrupacion) &&
        itemPedido.IdAgrupacion === itemFactura.IdAgrupacion &&
        itemPedido.Expediente === itemFactura.Expediente &&
        itemPedido.frozenFor === 'N'
    default:
      return false
  }
}

/* Funcion para agrupar los items de la factura por description y batch */
const agruparItemsPorBatch = (items) => {
  console.log('agruparItemsPorBatch items --> ', items)
  const mapa = {}

  items.forEach(item => {
    const clave = `${item.Description}_${item.BatchNumbers[0].DistNumber}_${item.BatchNumbers[0].ExpDate}`
    if (!mapa[clave]) {
      mapa[clave] = { ...item }
    } else {
      mapa[clave].InvoiceQuantity += item.InvoiceQuantityInicial
      mapa[clave].InvoiceQuantityInicial += item.InvoiceQuantityInicial
      mapa[clave].LineTotal += item.LineTotal
      mapa[clave].LineTotalCalculado += item.LineTotal
      mapa[clave].TaxTotal += item.TaxTotal
      mapa[clave].TaxTotalCalculado += item.TaxTotal
      mapa[clave].BatchNumbers[0].Quantity = item.BatchNumbers[0].Quantity
    }
  })

  return Object.values(mapa)
}

/* Funcion para setear el item seleccionado del pedido y realizar la distribucion de bodegas dependiendo lo del pedido */
const distribucionBodegasArticuloPedidoFactura = async () => {
  const processedDescriptions = new Set() // Crear un conjunto para almacenar las Descriptions procesadas

  infoPedidos.value.forEach((pedido) => {
    const itemFactura = InvoiceCopy.value.Items.filter((itemFac) =>
      findItemByFilter(selectedFilterSearch.value, pedido, itemFac)
    )

    console.log('distribucionBodegasArticuloPedidoFactura - itemFactura --> ', itemFactura)

    if (itemFactura.length === 1) {
      // Un solo item del mismo CUM
      console.log(
        'distribucionBodegasArticuloPedidoFactura - Un unico item con el mismo CUM...',
        itemFactura[0]
      )
      const pedidoQuantity = parseInt(pedido.Quantity)
      const pedidoConversion = parseInt(itemFactura[0].Conversion)
      const newItem = { ...itemFactura[0] }

      // Verificar si la Description ya ha sido procesada
      if (!processedDescriptions.has(newItem.Description)) {
        newItem.IsNotDeleted = true
        processedDescriptions.add(newItem.Description)
      } else {
        newItem.IsNotDeleted = false
      }

      // Modificar las propiedades necesarias
      newItem.IndexPedido = pedido.Index
      newItem.pedidoQuantity = pedidoQuantity
      newItem.pedidoQuantityOriginal = pedidoQuantity
      newItem.LineTotalPedido = parseFloat(pedido.LineTotal)
      console.log(
        'distribucionBodegasArticuloPedidoFactura - pedidoQuantity - pedidoConversion - PriceInicial --> ',
        pedidoQuantity,
        pedidoConversion,
        newItem.PriceInicial
      )
      // Validar que pedidoQuantity, pedidoConversion y newItem.PriceInicial no sean NaN
      if (
        [pedidoQuantity, pedidoConversion, newItem.PriceInicial].some((x) => isNaN(x)) ||
        [pedidoQuantity, pedidoConversion, newItem.PriceInicial].some((x) => x === null)
      ) {
        newItem.LineTotalDistribucion = 0
        newItem.Price = 0
        newItem.SinConversion = true
      } else {
        newItem.LineTotalDistribucion = (pedidoQuantity / pedidoConversion) * newItem.PriceInicial
        // Asignar el precio dependiendo el proveedor
        if (proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre)) {
          newItem.Price = parseInt(newItem.LineTotal) / parseInt(newItem.InvoiceQuantityInicial)
        } else {
          newItem.Price =
            parseInt(newItem.LineTotal) /
            (parseInt(newItem.InvoiceQuantityInicial) * pedidoConversion)
        }
        newItem.SinConversion = false
      }
      newItem.LineNum = pedido.LineNum
      newItem.DocNum = pedido.DocNum
      newItem.ItemCode = pedido.ItemCode
      newItem.ItemName = pedido.ItemName
      newItem.DocEntryPedido = pedido.DocEntry
      newItem.ObjTypePedido = pedido.ObjType
      newItem.InfoBodega = {
        WhsCode: pedido.WhsCode,
        WhsName: pedido.WhsName,
        bodega: pedido.bodega,
        U_PHR_CentroCosto: pedido.U_PHR_CentroCosto
      }
      newItem.InfoItem = {
        ItemCode: itemFactura[0].ItemCode,
        ItemName: itemFactura[0].ItemName,
        Conversion: itemFactura[0].Conversion,
        nombreCompleto: itemFactura[0].ItemCode + ' - ' + itemFactura[0].ItemName,
        Expediente: itemFactura[0].Expediente,
        Cum: itemFactura[0].CodCum,
        IdAgrupacion: itemFactura[0].IdAgrupacion,
        Agrupacion: itemFactura[0].Agrupacion,
        Laboratorio: itemFactura[0].LaboratorioSap,
        Presentacion: itemFactura[0].PresentacionSap,
        frozenFor: itemFactura[0].frozenFor,
        TaxCodeAP: itemFactura[0].TaxCodeAP,
        IVA: itemFactura[0].TaxPercent
      }

      newItem.IsSustituto = pedido.Cum !== itemFactura[0].CodCum

      newItem.InvoiceQuantity = pedidoQuantity
      newItem.BatchNumbers = newItem.BatchNumbers.map((x) => {
        if (newItem.BatchNumbers.length === 1) {
          return { ...x, Quantity: pedidoQuantity }
        } else {
          return { ...x, Quantity: x.Quantity * pedidoConversion }
        }
      })

      // Agregar el nuevo item de infoPedidos a los items de la factura
      newInvoiceItems.value.push(newItem)
      console.log('distribucionBodegasArticuloPedidoFactura - newItem --> ', newItem)
    } else {
      // Mismo item CUM, pero varias líneas porque se divide en los lotes
      console.log('distribucionBodegasArticuloPedidoFactura - Mismo CUM, varias lineas...')
      itemFactura.forEach((item) => {
        const pedidoQuantity = parseInt(item.InvoiceQuantityInicial) * parseInt(item.Conversion)
        const pedidoConversion = parseInt(pedido.Conversion)
        const newItem = { ...item }

        // Verificar si la Description ya ha sido procesada
        if (!processedDescriptions.has(newItem.Description)) {
          newItem.IsNotDeleted = true
          processedDescriptions.add(newItem.Description)
        } else {
          newItem.IsNotDeleted = false
        }

        // Modificar las propiedades necesarias
        newItem.IndexPedido = pedido.Index
        newItem.pedidoQuantity = pedidoQuantity
        newItem.pedidoQuantityOriginal = parseInt(pedido.Quantity)
        newItem.LineTotalPedido = parseFloat(pedido.LineTotal)
        newItem.LineTotalDistribucion = (pedidoQuantity / pedidoConversion) * newItem.PriceInicial
        newItem.LineNum = pedido.LineNum
        newItem.DocNum = pedido.DocNum
        newItem.ItemCode = pedido.ItemCode
        newItem.ItemName = pedido.ItemName
        newItem.DocEntryPedido = pedido.DocEntry
        newItem.ObjTypePedido = pedido.ObjType
        newItem.InfoBodega = {
          WhsCode: pedido.WhsCode,
          WhsName: pedido.WhsName,
          bodega: pedido.bodega,
          U_PHR_CentroCosto: pedido.U_PHR_CentroCosto
        }
        newItem.InfoItem = {
          ItemCode: pedido.ItemCode,
          ItemName: pedido.ItemName,
          Conversion: pedido.Conversion,
          nombreCompleto: pedido.nombreCompleto,
          Expediente: pedido.Expediente,
          Cum: pedido.Cum,
          IdAgrupacion: pedido.IdAgrupacion,
          Agrupacion: pedido.Agrupacion,
          Laboratorio: pedido.U_PHR_Lab,
          Presentacion: pedido.U_PHR_Presentacion,
          frozenFor: pedido.frozenFor,
          TaxCodeAP: pedido.TaxCodeAP,
          IVA: pedido.IVA
        }

        // Asignar el precio dependiendo el proveedor
        if (proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre)) {
          newItem.Price = parseInt(newItem.LineTotal) / parseInt(newItem.InvoiceQuantityInicial)
        } else {
          newItem.Price = parseInt(newItem.PriceInicial) / pedidoConversion
        }

        newItem.InvoiceQuantity = pedidoQuantity

        newItem.BatchNumbers = newItem.BatchNumbers.filter(
          (x) => x.Quantity !== 0 && x.DistNumber !== ''
        ).map((x) => ({ ...x, Quantity: pedidoQuantity }))

        // Agregar el nuevo item de infoPedidos a los items de la factura
        newInvoiceItems.value.push(newItem)
      })
    }
  })

  // Filtrar los elementos de InvoiceItems que no están en newInvoiceItems
  const filteredInvoiceItems = InvoiceCopy.value.Items.filter((itemFac) => {
    console.log(
      'distribucionBodegasArticuloPedidoFactura - No estoy en el pedido...',
      itemFac.CodCum
    )
    // Asignar el precio y la cantidad unitaria
    itemFac.Price = parseInt(itemFac.LineTotal) / parseInt(itemFac.InvoiceQuantityInicial)
    itemFac.InvoiceQuantity = parseInt(itemFac.InvoiceQuantityInicial)
    itemFac.pedidoQuantity = parseInt(itemFac.InvoiceQuantityInicial)
    itemFac.LineTotalDistribucion = itemFac.PriceInicial * itemFac.InvoiceQuantityInicial
    itemFac.IsNotDeleted = true

    return !newInvoiceItems.value.some((newItem) => newItem.CodCum === itemFac.CodCum)
  })

  // Concatenar los items de la factura con los nuevos items y los elementos filtrados de InvoiceItems
  Invoice.value.Items = newInvoiceItems.value.concat(filteredInvoiceItems)

  console.log('distribucionBodegasArticuloPedidoFactura - InvoiceItems --> ', Invoice.value.Items)
  // Agregar el Index para contar los items
  Invoice.value.Items.forEach((x, index) => {
    x.IndexFact = index + 1
  })
}

/* Cambio del item en la factura (Autocomplete) */
const onChangeItemFactura = (item) => {
  if (!item.InfoItem) {
    return
  }

  const isProveedorNoConversion = proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre)
  const hasLineNumAndDocNum = item.LineNum >= 0 && item.DocNum
  const invoiceQuantityNuevo = item.pedidoQuantity ? parseInt(item.pedidoQuantity) : parseInt(item.InvoiceQuantityInicial)
  const priceInicial = parseInt(item.LineTotal) / parseInt(item.InvoiceQuantityInicial)
  const conversion = parseInt(item.InfoItem.Conversion)

  // Obtener el item del pedido
  const itemPedido = infoPedidos.value.find((x) => {
    return x.Index === item.IndexPedido
  })
  console.log('onChangeItemFactura itemPedido --> ', itemPedido)

  if (!['', null, undefined].includes(itemPedido)) {
    item.IsSustituto = itemPedido.Cum !== item.InfoItem.Cum
  } else {
    item.IsSustituto = false
  }

  if (isProveedorNoConversion) {
    item.Price = priceInicial
    item.InvoiceQuantity = invoiceQuantityNuevo
  } else {
    if (hasLineNumAndDocNum) {
      item.Price = parseInt(item.LineTotalDistribucion) / invoiceQuantityNuevo
      item.InvoiceQuantity = invoiceQuantityNuevo
    } else {
      item.Price = priceInicial / conversion
      item.InvoiceQuantity = item.IsLineOriginalFact ? invoiceQuantityNuevo * conversion : item.InvoiceQuantityInicial * conversion
    }
  }

  item.BatchNumbers.forEach((x) => {
    x.Quantity = item.InvoiceQuantity
  })
}

/* Funcion para mostrar los items del pedido en el sidebar */
const showSidebarItemsPedido = (dataPedido, dataFactura) => {
  openSidebarItemsPedido.value.openSidebar(dataPedido, dataFactura)
}

/* Funcion para buscar los medicamentos */
const searchMx = async ({ query }) => {
  if (!query.trim() || query.length <= 3) {
    medicamentosList.value = []
  } else {
    await _RecepcionPedidosService.value.getAllMds(query.toUpperCase()).then(({ data }) => {
      loadingItems.value = false
      medicamentosList.value = data
    }).catch((err) => {
      console.error(err.message)
    })
  }
}

/* TODO: -> Función que se activa cuando cambia el valor del item seleccionado en el autocomplete */
const onChangeMx = (event, data) => {
  console.log('onChangeMx data --> ', data)
  const mxInPedido = infoPedidos.value.filter((x) => {
    if (data.InfoItem && data.InfoItem.Cum && data.InfoBodega && data.InfoBodega.WhsCode) {
      return x.IdAgrupacion === data.InfoItem.IdAgrupacion && x.WhsCode === data.InfoBodega.WhsCode
    }
  })
  if (mxInPedido.length <= 0) {
    const mxInPedidoFalse = infoPedidos.value.find((x) => x.Index === data.IndexPedido)
    if (mxInPedidoFalse) {
      mxInPedidoFalse.isInPedido = false
      mxInPedidoFalse.IndexFact = null
    }

    Object.assign(data, {
      IndexPedido: null,
      LineNum: null,
      DocNum: null,
      ItemCode: null,
      ItemName: null,
      DocEntryPedido: null,
      ObjTypePedido: null,
      InfoBodega: null,
      IsSustituto: false,
      Price: parseInt(data.LineTotal) / parseInt(data.InvoiceQuantityInicial),
      InvoiceQuantity: parseInt(data.InvoiceQuantityInicial),
      BatchNumbers: data.BatchNumbers.map((x) => {
        return { ...x, Quantity: parseInt(data.InvoiceQuantity) }
      })
    })
  }
}

/* Agregar nueva fila en la factura */
const addRowInFactura = (data) => {
  console.log('addRowFact data --> ', data)
  // Agregar a arrTotalLinesInvoice el nuevo item
  const item = {
    Description: data.Description,
    CodCum: data.CodCum,
    TotalLineCalculado: parseInt(data.LineTotalCalculado),
    TaxTotal: parseInt(data.TaxTotal),
    TaxTotalCalculado: parseInt(data.TaxTotalCalculado),
    TaxPercent: parseInt(data.TaxPercent),
    SubTotal: parseInt(data.LineTotal),
    TotalLine: parseInt(data.LineTotal) + parseInt(data.TaxTotal),
    Quantity: parseInt(data.InvoiceQuantityInicial),
    Conversion: parseInt(data.Conversion),
    QuantityWithConversion: parseInt(data.InvoiceQuantityInicial) * parseInt(data.Conversion),
    IndexFact: Invoice.value.Items.length + 1,
    InvoiceQuantity: data.InvoiceQuantity
  }

  arrTotalLinesInvoice.value.push(item)

  const newItem = {
    Index: Invoice.value.Items.length + 1,
    IndexFact: Invoice.value.Items.length + 1,
    IndexPedido: null,
    IsLineOriginalFact: false,
    Description: data.Description,
    IdentificationItem: data.IdentificationItem,
    CodCum: data.CodCum ?? '',
    Presentacion: data.Presentacion ?? '',
    PresentacionSap: data.PresentacionSap ?? '',
    Laboratorio: data.Laboratorio ?? '',
    LaboratorioSap: data.LaboratorioSap ?? null,
    BatchNumbers: [
      {
        ExpDate: data.BatchNumbers[0].ExpDate,
        DistNumber: data.BatchNumbers[0].DistNumber,
        Quantity: proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre) ? parseInt(data.InvoiceQuantityInicial) : parseInt(data.InvoiceQuantityInicial) * parseInt(data.Conversion)
      }
    ],
    InvoiceQuantity: proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre) ? parseInt(data.InvoiceQuantityInicial) : parseInt(data.InvoiceQuantityInicial) * parseInt(data.Conversion),
    InvoiceQuantityInicial: data.InvoiceQuantityInicial,
    Price: data.Price,
    PriceInicial: data.PriceInicial,
    LineTotal: data.LineTotal,
    LineTotalCalculado: data.LineTotalCalculado,
    TaxPercent: data.TaxPercent,
    TaxTotal: data.TaxTotal,
    TaxTotalCalculado: data.TaxTotalCalculado,
    InfoItem: null,
    InfoBodega: null,
    LineNum: null,
    DocNum: null,
    DocEntryPedido: null,
    ObjTypePedido: null,
    IdAgrupacion: data.IdAgrupacion ?? null,
    Agrupacion: data.Agrupacion ?? null,
    frozenFor: data.frozenFor ?? null,
    Expediente: data.Expediente ?? null,
    Conversion: data.Conversion ?? null,
    ItemCode: data.ItemCode ?? null,
    ItemName: data.ItemName ?? null,
    IsSustituto: false,
    pedidoQuantity: data.pedidoQuantity,
    pedidoQuantityOriginal: data.pedidoQuantityOriginal,
    LineTotalDistribucion: data.LineTotalDistribucion
  }

  Invoice.value.Items.push(newItem)

  // Mensaje toast
  toast.add({
    severity: 'success',
    summary: 'Línea agregada',
    detail: 'La línea de la factura se agregó correctamente.',
    life: 3000
  })
}

/* Función para eliminar la línea de la factura */
const deleteRowInFactura = (index) => {
  const indexItem = Invoice.value.Items.findIndex((x) => x.IndexFact === index)
  const indexTotalLine = arrTotalLinesInvoice.value.findIndex((x) => x.IndexFact === index)
  const indexFact = Invoice.value.Items[indexItem].IndexFact

  // Validar que no se elimine la última línea
  if (Invoice.value.Items.length === 1) {
    toast.add({
      severity: 'warn',
      summary: 'Validación',
      detail: 'No se puede eliminar la última línea de la factura.',
      life: 3000
    })
    return
  }

  // Si, el item tiene IsNotDeleted en true no se puede eliminar
  if (Invoice.value.Items[indexItem].IsNotDeleted) {
    toast.add({
      severity: 'warn',
      summary: 'Validación',
      detail: 'No se puede eliminar la línea debido a que hace parte de la factura.',
      life: 3000
    })
    return
  }

  // Actualizar los IndexFact en arrTotalLinesInvoice
  arrTotalLinesInvoice.value.splice(indexTotalLine, 1)
  arrTotalLinesInvoice.value.forEach((x, index) => {
    x.Index = index + 1
    x.IndexFact = index + 1
  })

  // Actualizar los IndexFact de los items de la factura
  Invoice.value.Items.splice(indexItem, 1)
  Invoice.value.Items.forEach((x, index) => {
    x.Index = index + 1
    x.IndexFact = index + 1
  })

  // Actualizar los IndexFact de los items de la factura en el pedido
  infoPedidos.value.forEach((x) => {
    if (x.IndexFact > indexFact) {
      x.IndexFact -= 1
    }
  })

  // Buscar el item en el pedido con el mismo IndexFact y cambiar el isInPedido a false
  const mxInPedido = infoPedidos.value.find((x) => x.IndexFact === indexFact)
  if (mxInPedido) {
    mxInPedido.isInPedido = false
    mxInPedido.IndexFact = null
  }

  // Mensaje toast
  toast.add({
    severity: 'success',
    summary: 'Línea eliminada',
    detail: 'La línea de la factura se eliminó correctamente.',
    life: 3000
  })
}

/* Funcion para filtrar los medicamentos por el tipo en él autocomplete */
const filterMx = async (cum, { query }) => {
  const filterMx = allMedicamentosListAgrupaciones.value.filter((x) => x.Cum === cum)

  filterMedicamentosList.value = allMedicamentosListAgrupaciones.value.filter((x) => {
    if (filterMx[0].IdAgrupacion) {
      if (selectedFilterSearch.value === 1) {
        return x.IdAgrupacion === filterMx[0].IdAgrupacion
      } else if (selectedFilterSearch.value === 2) {
        return x.IdAgrupacion === filterMx[0].IdAgrupacion && x.Conversion === filterMx[0].Conversion
      } else if (selectedFilterSearch.value === 3) {
        return x.IdAgrupacion === filterMx[0].IdAgrupacion && x.Expediente === filterMx[0].Expediente
      }
    } else {
      return x.Cum === filterMx[0].Cum
    }
  })

  // Buscar por el query sobre filterMedicamentosList en los campos nombreCompleto o Cum
  if (query.trim().length) {
    filterMedicamentosList.value = filterMedicamentosList.value.filter((x) => {
      return x.nombreCompleto.toUpperCase().includes(query.toUpperCase()) || x.Cum.toUpperCase().includes(query.toUpperCase())
    })
  }
}

/* Setear los CUMs que no tienen agrupación o no existen */
const setCumsNoAgrupExist = async () => {
  cumsNoAgrupExist.value = Invoice.value.Items.filter((x) => !x.IdAgrupacion)
}

/* Setear color si tiene lotes o no asignados */
const setClassBgColorLotes = (batchNumbers) => {
  // Validar de que al menos tenga 1 lotes y que los 3 campos de ExpDate, DistNumber y Quantity no estén vacíos
  const hasBatchNumbers = batchNumbers.some((x) => x.ExpDate && x.DistNumber && x.Quantity)
  return hasBatchNumbers ? 'p-button-success' : 'p-button-warning'
}

/* Funcion para mostrar los CUMs que no existen o no tienen agrupación */
const showCumsNoAgrupacion = () => {
   if (cumsNoAgrupExist.value.length > 0) {
    Swal.fire({
      icon: 'info',
      title: 'Información',
      width: '50rem',
      html: `
        <div class="flex flex-col flex-wrap">
          <p class="text-base">Los siguientes artículos de la factura no tienen asociada una agrupación o no existen en SAP:</p>
          <div class="text-xs" style="max-height: 300px; overflow-y: auto;">
            <table class="w-full mt-2">
              <thead>
                <tr>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Cod Cum</th>
                </tr>
              </thead>
              <tbody>
                ${cumsNoAgrupExist.value.map((x) => {
                  return `
                    <tr>
                      <td class="text-left">${x.Description}</td>
                      <td class="text-left">${x.CodCum}</td>
                    </tr>
                  `
                }).join('')}
              </tbody>
            </table>
          </div>
          <p class="mt-4 text-xs text-gray-600 underline">Por favor, cree o asigne la agrupación a los ${cumsNoAgrupExist.value.length} artículos en SAP e intente de nuevo.</p>
        </div>
      `
    })
  }
}

// Mensaje de validación
const generateMessageValidation = (description, items, msgFooter) => {
  Swal.fire({
    icon: 'warning',
    title: 'Validación',
    width: '50rem',
    html: `
      <div class="flex flex-col flex-wrap">
        <p class="text-base">${description}</p>
        <div class="text-xs" style="max-height: 300px; overflow-y: auto;">
          <table class="w-full mt-2">
            <thead>
              <tr>
                <th class="text-left">Nombre</th>
                <th class="text-left">Linea Factura</th>
                <th class="text-left">Cod Cum</th>
              </tr>
            </thead>
            <tbody>
              ${items.map((x) => {
                return `
                  <tr>
                    <td class="text-left">${x.Description}</td>
                    <td class="text-left">${x.IndexFact}</td>
                    <td class="text-left">${x.CodCum}</td>
                  </tr>
                `
              }).join('')}
            </tbody>
          </table>
        </div>
        <p class="mt-4 text-xs text-gray-600 underline">${msgFooter}</p>
      </div>
    `
  })
}

// Mensaje de validación de los items que superan el valor de LineTotal con el LineTotalCalculado
const generateMessageValidationLineTotal = async (items, totalLineas, docTotal) => {
  isValidatePreliminar.value = totalLineas === docTotal

  console.log('Items in validation --> ', items)
  let noApplyConversion = false
  const finalResult = Object.values(items.reduce((acc, item) => {
    const QuantityRepeat = item.Quantity
    const TotalLineRepeat = item.TotalLine
    const key = `${item.Description}_${item.CodCum}`

    const itemPedido = infoPedidos.value.find((x) => {
      return x.Index === item.IndexPedido
    })

    if (!acc[key]) {
      acc[key] = {
        Description: item.Description,
        CodCum: item.CodCum,
        SubTotal: item.SubTotal,
        TaxPercent: item.TaxPercent,
        TaxTotal: item.TaxTotal,
        TotalLine: item.TotalLine,
        Quantity: item.Quantity,
        QuantityRepeat: item.Quantity,
        TotalLineRepeat: item.TotalLine,
        Conversion: item.Conversion ? item.Conversion : item.ConversionMxAsignado,
        QuantityWithConversion: item.Conversion ? item.Quantity * item.Conversion : item.Quantity * item.ConversionMxAsignado,
        IsUnique: item.IsUnique,
        IsLineOriginalFact: item.IsLineOriginalFact,
        IsSustituto: item.IsSustituto,
        ItemCodePedido: itemPedido ? itemPedido.ItemCode : null,
        ItemNamePedido: itemPedido ? itemPedido.ItemName : null,
        CodCumPedido: itemPedido ? itemPedido.Cum : null,
        Items: []
      }
    } else {
      // Si el item IsLineOriginalFact es false no realizar la suma de Quantity y TotalLine
      if (item.IsLineOriginalFact) {
        acc[key].QuantityRepeat += QuantityRepeat
        acc[key].TotalLineRepeat += TotalLineRepeat
      }
    }
    acc[key].Items.push({
      IndexFact: item.IndexFact,
      NomMxAsignado: item.ItemNameMxAsignado,
      CumMxAsignado: item.CumMxAsignado,
      BodegaMxAsignado: item.BodegaMxAsignado,
      ConversionMxAsignado: item.ConversionMxAsignado,
      QuantityWithConversion: item.Conversion ? item.Quantity * item.Conversion : item.Quantity * item.ConversionMxAsignado,
      InvoiceQuantity: item.InvoiceQuantity,
      TotalLineCalculado: item.TotalLineCalculado,
      IsSustituto: item.IsSustituto,
      ItemCodePedido: itemPedido ? itemPedido.ItemCode : null,
      ItemNamePedido: itemPedido ? itemPedido.ItemName : null,
      CodCumPedido: itemPedido ? itemPedido.Cum : null
    })
    return acc
  }, {}))

  finalResult.forEach(item => {
    item.TotalLineCalculadoFactura = item.Items.reduce((sum, current) => sum + Math.round(parseFloat(current.TotalLineCalculado)), 0)
    item.QuantityAsignado = item.Items.reduce((sum, current) => sum + parseInt(current.InvoiceQuantity), 0)
    item.QuantityWithConversionRepeat = parseInt(item.QuantityRepeat) * parseInt(item.Conversion)
  })

  if (proveedoresWithoutConversion.value.includes(infoProveedor.value.nombre)) {
    noApplyConversion = true
  }

  const data = {
    items: finalResult,
    totalLineas,
    docTotal,
    noApplyConversion,
    isValidatePreliminar: isValidatePreliminar.value,
    DocDate: Invoice.value.DocDate,
    TaxDate: Invoice.value.TaxDate
  }

  console.log('Data validada --> ', data)

  openModalValidation(data)
}

/* Validaciones y envio de la data */
const onSubmit = async () => {
  // Filtrar unicamente los items que tengan IdAgrupacion diferente a null
  // const itemsFacturaExist = Invoice.value.Items.filter((x) => ![null, undefined, ''].includes(x.IdAgrupacion))

  // Obtener los items que no tienen medicamento o bodega
  const itemsNoInfoMdBod = Invoice.value.Items.filter((x) => !x.InfoItem || !x.InfoBodega)

  if (itemsNoInfoMdBod.length > 0) {
    const description = 'Los siguientes artículos de la factura no se les ha asignado un medicamento o bodega:'
    const msgFooter = `Por favor, asigne el medicamento y bodega correspondiente a los ${itemsNoInfoMdBod.length} artículos e intente de nuevo.`

    generateMessageValidation(description, itemsNoInfoMdBod, msgFooter)

    return
  }

  // Obtener los items que no tienen lote y distNumber y ExpDate
  const itemsConLoteIncompleto = Invoice.value.Items.filter((x) =>
    x.BatchNumbers.length > 0 &&
    x.BatchNumbers.some((y) => !y.DistNumber || !y.ExpDate)
  )

  if (itemsConLoteIncompleto && itemsConLoteIncompleto.length > 0) {
    const description = 'Los siguientes artículos de la factura no tienen la información completa de los lotes (Lote - Fec. Venc):'
    const msgFooter = `Por favor, asigne la información completa de los lotes a los ${itemsConLoteIncompleto.length} artículos e intente de nuevo.`

    generateMessageValidation(description, itemsConLoteIncompleto, msgFooter)

    return
  }

  // Mostrar los items que no tiene la cantidad total de los lotes igual a la cantidad de la factura
  const itemsSinCantidades = Invoice.value.Items.filter((x) => {
    const cantidadLotes = x.BatchNumbers.reduce((a, b) => a + parseInt(b.Quantity), 0)
    return parseInt(x.InvoiceQuantity) !== cantidadLotes
  })

  if (itemsSinCantidades.length > 0) {
    const description = 'Los siguentes artículos de la factura no tienen la cantidad total de los lotes igual a la cantidad de la factura o no se le ha asignado cantidades:'
    const msgFooter = `Por favor, asigne las cantidades a los ${itemsSinCantidades.length} artículos e intente de nuevo.`

    generateMessageValidation(description, itemsSinCantidades, msgFooter)

    return
  }

  // Validar que no venga vacia Invoice.TaxDate
  if (['', null, undefined].includes(Invoice.value.TaxDate)) {
    Swal.fire({
      icon: 'warning',
      title: 'Validación',
      text: 'Por favor, seleccione la fecha de recibido de la factura.'
    })

    return
  }

  // Validar que el valor total de las líneas no supere el valor total de la factura
  const totalLineas = parseInt(Invoice.value.Items.reduce((a, b) => a + b.LineTotalCalculado, 0))
  await generateMessageValidationLineTotal(arrTotalLinesInvoice.value, totalLineas, Invoice.value.DocTotal)
}

// Función para guardar la preliminar
const onSubmitSavePreliminar = () => {
  if (isValidatePreliminar.value) {
    messageConfirmSave('', 'Información', 'warning', 'Esta seguro de crear la preliminar para la entrada de mercancia').then(({ isConfirmed }) => {
      if (isConfirmed) {
        _RecepcionPedidosService.value.crearPreliminarEntrada(Invoice.value).then(({ data }) => {
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: `La preliminar de entrada #${data.Id} se ha creado correctamente`
          }).then(() => {
            router.push({ name: 'pharmasan.compras.logistica.cargue-facturas.listado' })
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    Swal.fire({
      icon: 'warning',
      title: 'Validación',
      text: 'El valor total de las líneas no coincide con el valor total de la factura.'
    })
  }
}

/* Resetear la información de la factura y pedidos */
const onReset = () => {
  Swal.fire({
    icon: 'question',
    title: '¿Esta seguro/a?',
    text: '¿Desea eliminar el contenido de esta tabla?',
    showConfirmButton: true,
    focusCancel: true,
    showCancelButton: true,
    confirmButtonText: 'Si, eliminar',
    cancelButtonText: 'No, cancelar'
  }).then((resp) => {
    if (resp.isConfirmed) {
      Invoice.value = {
        InvoiceNumber: null,
        CardName: null,
        DocDate: null,
        Comments: null,
        JournalMemo: null,
        SubTotal: null,
        DocTotalBeDi: null,
        DocTotal: null,
        TaxTotal: null,
        DiscTotal: 0,
        Items: []
      }

      clearPedidos()
    }
  })
}

/* Funcion para resaltar el item dentro del pedido */
const scrollToRowPedido = (e) => {
  const rowId = `ped_id_${e.DocNum}_${e.LineNum}`
  highlightedRowId.value = rowId
  const rowElement = document.getElementById(rowId)

  if (rowElement) {
    rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }
}

/* Funcion para resaltar el item dentro de la factura */
const scrollToRowFactura = (e) => {
  const rowId = `fact_id_${e.DocNum}_${e.LineNum}`
  highlightedRowId.value = rowId
  const rowElement = document.getElementById(rowId)

  if (rowElement) {
    rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }
}

// Observar el cambio de la variable highlightedRowId
watch(highlightedRowId, (newVal, oldVal) => {
  if (newVal) {
    setTimeout(() => {
      highlightedRowId.value = null
    }, 3000)
  }
})

/* Hooks */
onMounted(async () => {
  await getBodegas()
  menuCollapse(route)
})

</script>

<template>
  <Toast />

  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <!-- Titulo Card -->
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-lg mr-auto">{{ 'Nuevo Cargue de Factura XML' }}</h2>
          <!-- Mostrar un boton para cargar excel de lotes si el CardName es DISFARMA GC SAS -->
          <Button
            label="Cargar Excel de Lotes"
            icon="pi pi-upload"
            @click="openModalCargueLotesDisfarma(Invoice)"
            size="small"
            severity="success"
            class="p-button-rounded mr-2"
            v-if="Invoice.CardName === 'DISFARMA GC SAS' && infoPedidos.length > 0"
          />
          <Button
            label="Eliminar cargue"
            icon="pi pi-trash"
            @click="onReset"
            size="small"
            severity="danger"
            class="p-button-rounded"
            v-if="Invoice.Items.length !== 0"
          />
        </div>
        <!-- Body Card -->
        <div class="p-4">
          <!-- Dropzone -->
          <div id="drop-zone" class="p-2" v-if="Invoice.Items.length === 0">
            <div
              class="drop-zone"
              :class="{ 'hover': isDragging }"
              @dragover="dragOver"
              @dragleave="dragLeave"
              @drop="handleDrop"
            >
              Arrastre su archivo XML hasta aquí
            </div>
            <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" multiple>
          </div>

          <!-- Contenido -->
          <div class="grid grid-cols-12 gap-2" v-else-if="!existFactura && !existFacturaPg">
            <!-- Busqueda de pedidos -->
            <div class="col-span-12">
              <div class="flex w-full flex-col lg:flex-row mb-2">
                <div class="flex flex-col w-full">
                  <label for="pedidos" class="w-full">Número de Pedidos: <small class="text-gray-400">Digite los números de pedidos separado por comas (Ex: 111,122,...)</small></label>
                  <Chips
                    id="pedidos"
                    v-model="numPedidos"
                    separator=","
                    class="w-full"
                    :allowDuplicate="false"
                    addOnBlur
                    :pt="{
                      container: { style: 'width: 100%;' },
                      input: { style: 'font-size: 0.75rem' },
                      inputToken: { style: 'font-size: 0.7rem; padding: 0' },
                      token: { style: 'font-size: 0.75rem; padding: 0rem 0.4rem' }
                    }"
                  />
                </div>
                <div class="flex-grow flex flex-col w-full lg:w-1/3 lg:ml-2 lg:mr-2">
                  <label for="filterSearch" class="w-full">Tipo de busqueda:</label>
                  <Dropdown
                    id="filterSearch"
                    v-model="selectedFilterSearch"
                    :options="listFilterSearch"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Seleccione una opción"
                    :filter="true"
                    filterPlaceholder="Buscar..."
                    class="w-full text-sm"
                    :pt="{
                      root: { style: 'width: 100%; height: 34px' },
                      input: { style: 'font-size: 0.75rem; padding: 0.35rem 0.5rem' },
                      panel: { style: 'font-size: 0.75rem' },
                      item: { style: 'padding: 0.5rem 1rem' }
                    }"
                  />
                </div>
                <div class="flex-grow flex justify-end items-end gap-2 ml-2 mt-2 lg:mt-0">
                  <Button
                    icon="pi pi-search"
                    severity="primary"
                    class="p-button-xs"
                    style="height: 32px"
                    v-tippy="{ content: 'Buscar' }"
                    @click="searchPedido"
                  />
                  <Button
                    icon="pi pi-trash"
                    severity="danger"
                    class="p-button-xs"
                    style="height: 32px"
                    v-tippy="{ content: 'Limpiar' }"
                    @click="clearPedidos"
                  />
                  <!-- Boton para mostrar y ocultar pedido -->
                  <Button
                    :label="hidePedido ? 'Mostrar Pedido' : 'Ocultar Pedido'"
                    :icon="hidePedido ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    :disabled="disabledButtonPedido"
                    severity="secondary"
                    size="small"
                    style="height: 32px; width: max-content"
                    @click="viewPedido"
                  />
                </div>
              </div>
            </div>

            <!-- Información de los Pedidos -->
            <div v-if="!hidePedido" :class="`${hidePedido ? '' : 'col-span-12 lg:col-span-5'}`">
              <Card
                :pt="{
                  content: { style: 'padding: 0.5rem 0' }
                }"
              >
                <template #title>
                  <div class="flex flex-wrap items-end">
                    <span class="text-2xl mr-2">PEDIDO(S) #</span>
                    <span class="text-2xl font-normal underline text-gray-700">{{ numPedidosConsultados }}</span>
                  </div>
                </template>
                <template #content>
                  <div class="flex justify-between pb-4">
                    <div>
                      <div style="font-size: smaller;">
                        <strong>Nombre Proveedor: </strong>{{ infoPedidos[0].CardName }}
                      </div>
                      <div style="font-size: smaller;">
                        <strong>Fecha Pedido: </strong>{{ infoPedidos[0].DocDate }}
                      </div>
                      <div style="font-size: smaller;">
                        <strong>Items en los pedidos: </strong>{{ infoPedidos.length }}
                      </div>
                    </div>
                  </div>

                  <DataTable
                    :value="infoPedidos"
                    class="p-datatable-sm text-xs custom-scrollbar"
                    showGridlines
                    dataKey="Index"
                    responsiveLayout="scroll"
                    scrollHeight="750px"
                    :scrollable="true"
                    ref="tableList"
                    removableSort
                    :pt="{
                      thead: { style: 'top: -1px' }
                    }"
                  >
                    <template #empty>
                      No se encontraron datos registrados.
                    </template>
                    <Column
                      field="ItemCode"
                      header="Descripción Artículo"
                      style="max-width:28rem"
                    >
                      <template #body="{ data }">
                        <div class="flex flex-col" :id="`ped_id_${data.DocNum}_${data.LineNum}`" :class="{ highlight: `ped_id_${data.DocNum}_${data.LineNum}` === highlightedRowId }">
                          <div class="flex flex-wrap justify-between">
                            <span class="custom-ellipsis"><strong>Código Mx: </strong>{{ data.ItemCode }}</span>
                            <div class="flex flex-wrap">
                              <Badge
                                :value="`#${data.Index}`"
                                style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                class="bg-gray-300 text-gray-900 capitalize cursor-pointer font-bold"
                              />
                              <Badge
                                :value="`Pedido: ${data.DocNum} - Linea: ${data.LineNum}`"
                                style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                class="bg-blue-100 text-blue-900 capitalize cursor-pointer font-bold"
                                v-tippy="{ content: `
                                <div class='flex flex-col'>
                                  <span>Fecha Pedido: ${data.DocDate}</span>
                                  <span>Comentarios: ${![null, undefined].includes(data.Comments) ? data.Comments : ''}</span>
                                  <span>Total Pedido: ${$h.formatCurrency(data.DocTotal)}</span>
                                </div>
                              `, trigger: 'click' }"
                              />
                            </div>
                          </div>
                          <span
                            class="custom-ellipsis"
                            v-tippy="{ content: `
                              ${data.Dscription}
                            `, trigger: 'click' }"
                          >
                            <strong>Nombre: </strong>{{ data.Dscription }}
                          </span>
                          <span class="custom-ellipsis"><strong>Laboratorio: </strong>{{ data.U_PHR_Lab }}</span>
                          <span class="custom-ellipsis"><strong>Presentación: </strong>{{ data.U_PHR_Presentacion }}</span>
                          <div class="flex flex-wrap justify-between">
                            <!-- <pre>{{ JSON.stringify(data.InfoItem, null, '\t') }}</pre> -->
                            <div class="flex flex-wrap">
                              <span class="font-bold mr-1">Cod. Cum:</span>
                              <span class="border-b border-dashed">
                                {{ data.Cum }}
                              </span>
                              <Icon
                                icon="mdi:content-copy"
                                class="ml-1 text-black cursor-pointer"
                                v-tippy="{ content: 'Copiar CUM al portapapeles' }"
                                @click="copyToClipboard(data.Cum)"
                              />
                              <!-- Mostrar un texto de Copiado y ocultarlo despues de 1 seg -->
                              <span
                                v-if="copiedCum === data.Cum"
                                class="text-xs text-green-500 ml-1"
                              >
                                Copiado
                              </span>
                            </div>
                            <Badge
                              v-if="data.isInPedido"
                              @click="scrollToRowFactura(data)"
                              :value="`En factura`"
                              style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                              class="bg-green-100 text-green-900 capitalize cursor-pointer font-bold"
                            />
                          </div>
                          <div class="flex justify-between">
                            <span
                              class="custom-ellipsis"
                              v-tippy="{ content: `
                                ${data.Agrupacion}
                              `, trigger: 'click' }"
                            >
                              <strong>Agrupación: </strong>{{ data.Agrupacion }}
                            </span>
                            <Badge
                              v-if="data.IdAgrupacion"
                              :value="`Id Agrup: ${data.IdAgrupacion}`"
                              style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                              class="whitespace-nowrap bg-gray-300 text-gray-900 capitalize cursor-pointer font-bold"
                            />
                          </div>
                          <span class="custom-ellipsis"><strong>Bodega: </strong>{{ data.bodega }}</span>
                        </div>
                      </template>
                    </Column>
                    <Column
                      field="Quantity"
                      header="Valores"
                      style="width:20rem"
                    >
                      <template #body="{ data }">
                        <div class="flex-col w-full" :id="`ped_id_${data.DocNum}_${data.ItemCode}_${data.LineNum}`" :class="{ highlight: `ped_id_${data.DocNum}_${data.ItemCode}_${data.LineNum}` === highlightedRowId }">
                          <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                            <span class="font-bold">Cant. Total:</span>
                            <span class="text-blue-700">{{ parseInt(data.Quantity) }}</span>
                          </div>
                          <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                            <span class="font-bold">Cant. Pendiente:</span>
                            <span class="text-red-700">{{ parseInt(data.OpenQty) }}</span>
                          </div>
                          <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                            <span class="font-bold">Valor Unitario:</span>
                            <span>{{ helper.formatCurrency(parseInt(data.Price)) }}</span>
                          </div>
                          <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                            <span class="font-bold">Valor Total:</span>
                            <span>{{ helper.formatCurrency(parseInt(data.LineTotal)) }}</span>
                          </div>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </template>
              </Card>
            </div>

            <!-- Información de la factura -->
            <div :class="`${hidePedido ? 'col-span-12' : 'col-span-12 lg:col-span-7'}`">
              <Card
                :pt="{
                  content: { style: 'padding: 0.5rem 0' }
                }"
              >
                <template #title>
                  <div class="flex flex-wrap justify-between items-end">
                    <div class="flex">
                      <span class="text-2xl mr-2">FACTURA #</span>
                      <span class="text-2xl font-normal underline text-gray-700"> {{ Invoice.InvoiceNumber }}</span>
                    </div>
                    <div class="flex" v-if="cumsNoAgrupExist.length > 0">
                      <span class="text-xs mr-2">Items no existentes o sin agrupación: </span>
                      <Badge
                        @click="showCumsNoAgrupacion"
                        :value="`${cumsNoAgrupExist.length}`"
                        class="text-xs h-full cursor-pointer font-normal bg-red-700"
                      />
                    </div>
                  </div>
                </template>
                <template #content>
                  <div class="flex flex-wrap justify-between items-start pb-4">
                    <div class="flex flex-col flex-wrap">
                      <div style="font-size: smaller;">
                        <strong>Nombre Proveedor: </strong>{{ Invoice.CardName }}
                      </div>
                      <div style="font-size: smaller;">
                        <strong>Fecha Factura: </strong>{{ Invoice.DocDate }}
                      </div>
                      <div style="font-size: smaller;">
                        <strong>Items Factura: </strong>{{ Invoice.Items.length }}
                      </div>
                    </div>
                    <div class="flex flex-row flex-wrap">
                      <span class="text-xs mr-1 font-bold">Fecha recibido factura:</span>
                      <InputText
                        id="TaxDate"
                        type="date"
                        class="w-full"
                        v-model="Invoice.TaxDate"
                        placeholder="Ingresa fecha de recibido factura"
                        :pt="{
                          root: ({
                            props, state, context
                          }) => ({
                            style: 'height: 1.7rem; font-size: 0.75rem;'
                          })
                        }"
                      />
                    </div>
                  </div>
                  <div>
                    <DataTable
                      :value="Invoice.Items"
                      class="p-datatable-sm text-xs custom-scrollbar"
                      showGridlines
                      dataKey="Index"
                      responsiveLayout="scroll"
                      scrollHeight="595px"
                      :scrollable="true"
                      resizableColumns
                      columnResizeMode="fit"
                      ref="tableList"
                      removableSort
                      :pt="{
                        thead: { style: 'top: -1px' }
                      }"
                    >
                      <template #empty>
                        No se encontraron datos registrados.
                      </template>
                      <Column
                        field="Description"
                        header="Descripción Articulo"
                        :style="`${infoPedidos.length > 0 ? 'max-width: 38rem; width: 38rem' : 'max-width: 40rem;'}`"
                      >
                        <template #body="{ data }">
                          <!-- <pre>{{ JSON.stringify(data, null, '\t') }}</pre> -->
                          <div class="flex flex-col" :id="`fact_id_${data.DocNum}_${data.LineNum}`" :class="{ highlight: `fact_id_${data.DocNum}_${data.LineNum}` === highlightedRowId }">
                            <div class="flex flex-wrap justify-between">
                              <span
                                class="custom-ellipsis flex-1"
                                v-tippy="{ content: `
                                  ${data.Description}
                                `, trigger: 'click' }"
                              >
                                <strong>Nombre: </strong>{{ data.Description }}
                              </span>
                              <div class="flex flex-wrap">
                                <Badge
                                  :value="`#${[null, undefined, ''].includes(data.IndexFact) ? data.Index : data.IndexFact}`"
                                  style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                  class="bg-gray-300 text-gray-900 capitalize cursor-pointer font-bold"
                                />
                              </div>
                            </div>
                            <span><strong>Laboratorio: </strong>{{ data.LaboratorioSap }}</span>
                            <span><strong>Presentación: </strong>{{ data.PresentacionSap }}</span>
                            <div class="flex flex-wrap justify-between">
                              <!-- <pre>{{ JSON.stringify(data.InfoItem, null, '\t') }}</pre> -->
                              <span><strong>Cod. Cum: </strong>{{ data.CodCum }}</span>
                              <Badge
                                v-if="![null, '', undefined].includes(data.DocNum) && ![null, '', undefined].includes(data.LineNum)"
                                @click="scrollToRowPedido(data)"
                                :value="`En Pedido: ${data.DocNum}`"
                                style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                class="bg-green-100 text-green-900 capitalize cursor-pointer font-bold"
                              />
                            </div>
                            <div class="flex justify-between">
                              <span
                                class="custom-ellipsis"
                                v-tippy="{ content: `
                                  ${data.Agrupacion}
                                `, trigger: 'click' }"
                              >
                                <strong>Agrupación: </strong>{{ data.Agrupacion }}
                              </span>
                              <Badge
                                v-if="data.IdAgrupacion"
                                :value="`Id Agrup: ${data.IdAgrupacion}`"
                                style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                class="whitespace-nowrap bg-gray-300 text-gray-900 capitalize cursor-pointer font-bold"
                              />
                            </div>
                            <div v-if="data.IdAgrupacion">
                              <div class="flex justify-between gap-2">
                                <div class="flex flex-col w-2/3">
                                  <div class="flex flex-wrap justify-between">
                                    <span class="font-bold mb-1">Medicamento asignado: </span>
                                    <Badge
                                      v-if="data.IsSustituto"
                                      :value="`Sustituto`"
                                      style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                      class="whitespace-nowrap bg-yellow-300 text-yellow-800 capitalize cursor-pointer font-bold"
                                    />
                                  </div>
                                  <AutoComplete
                                    v-model="data.InfoItem"
                                    :suggestions="filterMedicamentosList"
                                    @complete="filterMx(data.CodCum, $event)"
                                    @change="onChangeItemFactura(data)"
                                    optionLabel="nombreCompleto"
                                    emptySearchMessage="No se encontraron resultados"
                                    forceSelection
                                    dropdown
                                    :loading="loadingItems"
                                    placeholder="Digite al menos 4 caracteres para buscar un Medicamento..."
                                    class="text-xs w-full"
                                    inputClass="w-full"
                                    :disabled="infoPedidos.length <= 0"
                                    :pt="{
                                      root: { style: 'height: 1.7rem' },
                                      input: ({ props, state, context }) => {
                                        if (props.modelValue && props.modelValue.frozenFor === 'Y') {
                                          return { style: 'font-size: 0.75rem; background-color: #ffdddd; border-color: #b91c1c' }
                                        } else {
                                          return { style: 'font-size: 0.75rem' }
                                        }
                                      },
                                      panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                                      item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                                    }"
                                  >
                                    <template #option="{ option }">
                                      <div class="flex flex-col">
                                        <span class="font-bold" style="font-size: 0.7rem">{{ option.nombreCompleto }}</span>
                                        <div class="flex flex-wrap justify-between">
                                          <span style="font-size: 0.7rem">{{ option.Cum }}</span>
                                          <Badge
                                            :value="`${option.frozenFor === 'Y' ? 'Inactivo' : 'Activo'}`"
                                            style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                            class="whitespace-nowrap bg-gray-700 capitalize cursor-pointer font-normal"
                                            :class="`${option.frozenFor === 'Y' ? 'bg-red-700' : 'bg-green-700'}`"
                                          />
                                        </div>
                                      </div>
                                    </template>
                                  </AutoComplete>
                                </div>
                                <div class="flex flex-col w-1/3">
                                  <span class="font-bold mb-1">Bodega: </span>
                                  <AutoComplete
                                    v-model="data.InfoBodega"
                                    :suggestions="filteredBodegas"
                                    @complete="searchBodegas"
                                    @change="onChangeBodega($event, data)"
                                    @item-select="selectBodega($event, data)"
                                    optionLabel="bodega"
                                    optionValue="WhsCode"
                                    minLength="1"
                                    emptySearchMessage="No se encontraron resultados"
                                    forceSelection
                                    dropdown
                                    :loading="loadingItems"
                                    placeholder="Digite al menos 1 caracter para buscar la bodega..."
                                    class="text-xs w-full"
                                    inputClass="w-full"
                                    :disabled="infoPedidos.length <= 0"
                                    :pt="{
                                      root: { style: 'height: 1.7rem' },
                                      input: { style: 'font-size: 0.75rem' },
                                      panel: { style: 'font-size: 0.75rem' }
                                    }"
                                  />
                                </div>
                              </div>
                              <span
                                v-if="![null, '', undefined].includes(data.InfoItem)"
                                class="text-xxs text-blue-700 underline cursor-pointer font-bold"
                                v-tippy="{ content: `
                                  <div class='flex flex-col'>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Nombre:</span>
                                      <span class='underline'>${data.InfoItem.nombreCompleto ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Cod. Cum:</span>
                                      <span class='underline'>${data.InfoItem.Cum ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Expediente:</span>
                                      <span class='underline'>${data.InfoItem.Expediente ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Conversion:</span>
                                      <span class='underline'>${data.InfoItem.Conversion ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Agrupacion:</span>
                                      <span class='underline'>${data.InfoItem.Agrupacion ?? ''}</span>
                                    </div>
                                    <span class='text-xs font-bold ${data.InfoItem.frozenFor === 'Y' ? 'text-red-500' : 'text-green-500'}'>${data.InfoItem.frozenFor === 'Y' ? 'Inactivo' : 'Activo'}</span>
                                  </div>
                                `, trigger: 'click' }"
                              >
                                Ver info Mx seleccionado...
                              </span>
                            </div>
                            <div v-else> <!-- Si no hay agrupacion (no se tiene el CUM) -->
                              <div class="flex justify-between gap-2">
                                <div class="flex flex-col w-2/3">
                                  <div class="flex flex-wrap justify-between">
                                    <span class="font-bold mb-1">Medicamento asignado: </span>
                                    <Badge
                                      v-if="data.IsSustituto"
                                      :value="`Sustituto`"
                                      style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                      class="whitespace-nowrap bg-yellow-300 text-yellow-800 capitalize cursor-pointer font-bold"
                                    />
                                  </div>
                                  <AutoComplete
                                    v-model="data.InfoItem"
                                    :suggestions="medicamentosList"
                                    @complete="searchMx"
                                    @change="onChangeMx($event, data)"
                                    optionLabel="nombreCompleto"
                                    minLength="4"
                                    emptySearchMessage="No se encontraron resultados"
                                    forceSelection
                                    :virtualScrollerOptions="{ itemSize: 60 }"
                                    :loading="loadingItems"
                                    placeholder="Digite al menos 4 caracteres para buscar un Medicamento..."
                                    class="text-xs w-full"
                                    inputClass="w-full"
                                    :disabled="infoPedidos.length <= 0"
                                    :pt="{
                                      root: { style: 'height: 1.7rem' },
                                      input: ({ props, state, context }) => {
                                        if (props.modelValue && props.modelValue.frozenFor === 'Y') {
                                          return { style: 'font-size: 0.75rem; background-color: #ffdddd; border-color: #b91c1c' }
                                        } else {
                                          return { style: 'font-size: 0.75rem' }
                                        }
                                      },
                                      panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                                      item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                                    }"
                                  >
                                    <template #option="{ option }">
                                      <div class="flex flex-col">
                                        <span class="font-bold" style="font-size: 0.7rem">{{ option.nombreCompleto }}</span>
                                        <div class="flex flex-wrap justify-between">
                                          <span style="font-size: 0.7rem">{{ option.Cum }}</span>
                                          <Badge
                                            :value="`${option.frozenFor === 'Y' ? 'Inactivo' : 'Activo'}`"
                                            style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                                            class="whitespace-nowrap bg-gray-700 capitalize cursor-pointer font-normal"
                                            :class="`${option.frozenFor === 'Y' ? 'bg-red-700' : 'bg-green-700'}`"
                                          />
                                        </div>
                                      </div>
                                    </template>
                                  </AutoComplete>
                                </div>
                                <div class="flex flex-col w-1/3">
                                  <span class="font-bold mb-1">Bodega: </span>
                                  <AutoComplete
                                    v-model="data.InfoBodega"
                                    :suggestions="filteredBodegas"
                                    @complete="searchBodegas"
                                    @change="onChangeBodega($event, data)"
                                    @item-select="selectBodega($event, data)"
                                    optionLabel="bodega"
                                    optionValue="WhsCode"
                                    minLength="1"
                                    emptySearchMessage="No se encontraron resultados"
                                    forceSelection
                                    dropdown
                                    :loading="loadingItems"
                                    placeholder="Digite al menos 1 caracter para buscar la bodega..."
                                    class="text-xs w-full"
                                    inputClass="w-full"
                                    :disabled="infoPedidos.length <= 0 || [null, '', undefined].includes(data.InfoItem)"
                                    :pt="{
                                      root: { style: 'height: 1.7rem' },
                                      input: { style: 'font-size: 0.75rem' },
                                      panel: { style: 'font-size: 0.75rem' }
                                    }"
                                  />
                                </div>
                              </div>
                              <span
                                v-if="![null, '', undefined].includes(data.InfoItem)"
                                class="text-xxs text-blue-700 underline cursor-pointer font-bold"
                                v-tippy="{ content: `
                                  <div class='flex flex-col'>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Nombre:</span>
                                      <span class='underline'>${data.InfoItem.nombreCompleto ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Cod. Cum:</span>
                                      <span class='underline'>${data.InfoItem.Cum ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Expediente:</span>
                                      <span class='underline'>${data.InfoItem.Expediente ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Conversion:</span>
                                      <span class='underline'>${data.InfoItem.Conversion ?? ''}</span>
                                    </div>
                                    <div class='flex flex-wrap justify-between text-xs'>
                                      <span>Agrupacion:</span>
                                      <span class='underline'>${data.InfoItem.Agrupacion ?? ''}</span>
                                    </div>
                                    <span class='text-xs font-bold ${data.InfoItem.frozenFor === 'Y' ? 'text-red-500' : 'text-green-500'}'>${data.InfoItem.frozenFor === 'Y' ? 'Inactivo' : 'Activo'}</span>
                                  </div>
                                `, trigger: 'click' }"
                              >
                                Ver info Mx seleccionado...
                              </span>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column
                        field="Quantity"
                        header="Valores"
                        style="max-width: 8rem"
                      >
                        <template #body="{ data }">
                          <div class="flex-col w-full">
                            <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                              <div class="flex flex-wrap justify-between">
                                <span class="font-bold">Cant. Total:</span>
                                <Icon
                                  icon="mdi:information"
                                  class="text-blue-500 text-sm cursor-pointer"
                                  v-tippy="{ content: `
                                    <div class='flex justify-center' style='width: 200px'>
                                      <span class='font-bold text-blue-300 mb-2'>INFORMACIÓN FACTURA</span>
                                    </div>
                                    <div class='flex justify-between' style='width: 200px'>
                                      <span class='font-semibold'>Cantidad:</span>
                                      <span class='text-blue-200'>${data.InvoiceQuantityInicial}</span>
                                    </div>
                                    <div class='flex justify-between' style='width: 200px'>
                                      <span class='font-semibold'>Vlr Unidad:</span>
                                      <span class='text-blue-200'>${$h.formatCurrency(data.PriceInicial,2)}</span>
                                    </div>
                                    <div class='flex justify-between' style='width: 200px'>
                                      <span class='font-semibold'>Vlr Subtotal:</span>
                                      <span class='text-blue-200'>${$h.formatCurrency(data.LineTotal,2)}</span>
                                    </div>
                                    <div class='flex justify-between' style='width: 200px'>
                                      <span class='font-semibold'>Iva:</span>
                                      <span class='text-blue-200'>${data.TaxPercent}%</span>
                                    </div>
                                     <div class='flex justify-between' style='width: 200px'>
                                      <span class='font-semibold'>Vlr Iva:</span>
                                      <span class='text-blue-200'>${$h.formatCurrency(data.TaxTotal,2)}</span>
                                    </div>
                                    <div class='flex justify-between border-t border-dashed' style='width: 200px'>
                                      <span class='font-semibold'>Vlr total:</span>
                                      <span class='text-blue-200'>${$h.formatCurrency(data.LineTotal + data.TaxTotal,2)}</span>
                                    </div>

                                  `, trigger: 'click' }"
                                />
                              </div>
                              <InputText
                                id="InvoiceQuantity"
                                type="number"
                                :min="1"
                                class="w-full text-right"
                                placeholder="Ingresa la cantidad..."
                                @change="asignarCantidad(data)"
                                v-model="data.InvoiceQuantity"
                                :disabled="infoPedidos.length <= 0"
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem; font-size: 0.75rem;'
                                  })
                                }"
                              />
                            </div>
                            <div class="flex flex-wrap justify-between items-center mb-2">
                              <span class="font-bold">Vlr Und:</span>
                              <Icon
                                icon="mdi:alert"
                                class="text-yellow-600 text-sm cursor-pointer"
                                v-if="data.SinConversion"
                                v-tippy="{
                                  content:
                                    'Este medicamento no tiene conversion, por favor verificar'
                                }"
                              />
                              <InputNumber
                                v-model="data.Price"
                                inputId="currency-colombia"
                                :minFractionDigits="2"
                                :maxFractionDigits="4"
                                mode="currency"
                                class="w-full"
                                currency="COP"
                                locale="es-CO"
                                :disabled="infoPedidos.length <= 0"
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem'
                                  }),
                                  input: { style: 'width:100%; font-size: 0.75rem; text-align: right' }
                                }"
                              />
                            </div>
                            <div class="flex flex-col flex-wrap justify-between">
                              <div class="flex flex-wrap justify-between items-start">
                                <span class="font-bold text-right">Vlr SubTotal: </span>
                                <span class="mr-3"> {{ $h.formatCurrency(calcularTotalLinea(data).SubTotalCalculado, 2) }}</span>
                              </div>
                              <div class="flex flex-wrap justify-between items-start">
                                <span class="font-bold text-right">Vlr Iva: </span>
                                <span class="mr-3"><span v-if="data.TaxPercent > 0" class="text-xs">({{ data.TaxPercent }}%)</span> {{ $h.formatCurrency(calcularTotalLinea(data).TaxTotalCalculado, 2) }}</span>
                              </div>
                              <div class="flex flex-wrap justify-between items-start border-t border-dashed">
                                <span class="font-bold text-right">Vlr Total: </span>
                                <span class="mr-3"> {{ $h.formatCurrency(calcularTotalLinea(data).LineTotalCalculado, 2) }}</span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </Column>
                      <Column
                        field="BatchNumber"
                        header="Lote"
                        :bodyStyle="`${Invoice.Tipo === 'Manual' ? 'justify-content: center; text-align: center' : ''}`"
                        style="min-width: 4rem"
                      >
                        <template #body="{data}">
                          <div v-if="Invoice.Tipo === 'Manual' && Invoice.TipoLotes !== 'unico'">
                            <div v-if="data.BatchNumbers.length === 0">
                              <Button
                                type="button"
                                class="p-button-xs"
                                icon="pi pi-check"
                                label="Asignar"
                                @click="openModalAsignarLotes(data)"/>
                            </div>
                            <div
                              v-if="data.BatchNumbers.length > 0"
                              class="flex flex-col justify-center items-center gap-2"
                            >
                              <div class="flex flex-row flex-wrap justify-center gap-2">
                                <div>
                                  <Button
                                    type="button"
                                    icon="pi pi-eye"
                                    v-tooltip.top="'Ver Lotes'"
                                    :disabled="infoPedidos.length <= 0"
                                    @click="toggle($event,data)"
                                    aria-haspopup="true"
                                    aria-controls="overlay_panel"
                                    :pt="{
                                      root: ({ props, state, context }) => ({
                                        style: 'width: 2rem; height: 2rem; font-size: 0.75rem;'
                                      })
                                    }"
                                  />
                                  <OverlayPanel
                                    ref="op"
                                    appendTo="body"
                                    :showCloseIcon="true"
                                    id="overlay_panel"
                                    style="width: 500px"
                                    :breakpoints="{'960px': '75vw'}"
                                  >
                                    <DataTable
                                      :value="infoLotes"
                                      class="p-datatable-sm text-xs"
                                      showGridlines
                                      dataKey="id"
                                      scrollHeight="500px"
                                      :scrollable="true"
                                      responsiveLayout="scroll"
                                    >
                                      <Column
                                        field="DistNumber"
                                        header="Lote"
                                        headerStyle="justify-content: center;"
                                        style="min-width:5rem"
                                        bodyStyle="text-align: center"/>
                                      <Column
                                        field="Quantity"
                                        header="Cant."
                                        headerStyle="justify-content: center;"
                                        style="min-width:2rem"
                                        bodyStyle="text-align: center">
                                        <template #body="{data}">
                                          <span>{{ $h.formatNumber(data.Quantity) }}</span>
                                        </template>
                                      </Column>
                                      <Column
                                        field="ExpDate"
                                        header="F.Venc."
                                        headerStyle="justify-content: center;"
                                        style="min-width:5rem"
                                        bodyStyle="text-align: center"
                                      />
                                    </DataTable>
                                  </OverlayPanel>
                                </div>
                                <Button
                                  type="button"
                                  ref="btnEditLotes"
                                  :class="setClassBgColorLotes(data.BatchNumbers)"
                                  v-tooltip.top="'Editar Lotes'"
                                  icon="pi pi-pencil"
                                  :disabled="infoPedidos.length <= 0"
                                  @click="openModalAsignarLotes(data)"
                                  :pt="{
                                    root: ({ props, state, context }) => ({
                                      style: 'width: 2rem; height: 2rem; font-size: 0.75rem;'
                                    })
                                  }"
                                />
                              </div>
                              <div class="flex flex-row flex-wrap justify-center gap-2">
                                <Button
                                  icon="pi pi-plus"
                                  severity="secondary"
                                  :disabled="infoPedidos.length <= 0"
                                  @click="addRowInFactura(data)"
                                  v-tooltip.top="'Agregar Linea'"
                                  :pt="{
                                    root: ({ props, state, context }) => ({
                                      style: 'width: 2rem; height: 2rem; font-size: 0.75rem;'
                                    })
                                  }"
                                />
                                <Button
                                  icon="pi pi-trash"
                                  severity="danger"
                                  :disabled="infoPedidos.length <= 0"
                                  @click="deleteRowInFactura(data.IndexFact)"
                                  v-tooltip.top="'Eliminar Linea'"
                                  :pt="{
                                    root: ({ props, state, context }) => ({
                                      style: 'width: 2rem; height: 2rem; font-size: 0.75rem;'
                                    })
                                  }"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="flex-col"
                            v-for="(item) in data.BatchNumbers"
                            :key="item.indice"
                          >
                            <div class="flex flex-wrap justify-between items-center mb-1">
                              <span class="font-bold">Lote:</span>
                              <InputText
                                id="BatchNumber"
                                type="text"
                                class="w-full"
                                v-model="item.DistNumber"
                                placeholder="Ingresa el lote..."
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem; font-size: 0.75rem;'
                                  })
                                }"
                              />
                            </div>

                            <div class="flex flex-wrap justify-between items-center mb-1">
                              <span class="font-bold">Fec. Venc:</span>
                              <InputText
                                id="ExpDate"
                                type="date"
                                class="w-full"
                                v-model="item.ExpDate"
                                placeholder="Ingresa la fecha de vencimiento..."
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem; font-size: 0.75rem;'
                                  })
                                }"
                              />
                            </div>

                            <div class="flex flex-wrap justify-between items-center">
                              <span class="font-bold">Cantidad: </span>
                              <InputNumber
                                id="InvoiceQuantity"
                                :min="1"
                                disabled
                                class="w-full"
                                v-model="item.Quantity"
                                :useGrouping="false"
                                :pt="{
                                  root: ({
                                    props, state, context
                                  }) => ({
                                    style: 'height: 1.7rem'
                                  }),
                                  input: { style: 'width:100%; font-size: 0.75rem; text-align: right' }
                                }"
                              />
                            </div>
                          </div>
                        </template>
                      </Column>
                    </DataTable>
                  </div>
                </template>
                <template #footer>
                  <div class="w-full pt-2 grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-8 lg:col-span-2">
                      <div class="flex flex-col">
                        <p class="text-xs mb-2">
                          <strong>Comentarios: </strong>
                        </p>
                        <Textarea
                          class="p-inputtext-xs"
                          :autoResize="true"
                          v-model="Invoice.Comments"
                          rows="3"
                          cols="20"
                        />
                      </div>
                      <div class="flex flex-col">
                        <p class="text-xs mb-2">
                          <strong>Entrada de diario: </strong>
                        </p>
                        <Textarea
                          class="p-inputtext-xs"
                          disabled
                          :autoResize="true"
                          v-model="Invoice.JournalMemo"
                          rows="3"
                          cols="20"
                        />
                      </div>
                    </div>
                    <div class="text-xs lg:col-span-1">
                      <div class="flex justify-between border-b-2 pb-3">
                        <span class="text-gray-600">Sub Total Factura: </span>
                        <span class="">{{ $h.formatCurrency(Invoice.SubTotal) }}</span>
                      </div>
                      <div class="flex justify-between my-3">
                        <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
                        <span class="">{{ $h.formatCurrency(Invoice.DiscTotal) }}</span>
                      </div>
                      <div class="flex justify-between my-3">
                        <span class="text-gray-600">Total IVA:</span>
                        <span class="">{{ $h.formatCurrency(Invoice.TaxTotal) }}</span>
                      </div>
                      <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
                        <span class="font-bold">Total Factura:</span>
                        <span class="">{{ $h.formatCurrency(Invoice.DocTotal) }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <Button
    v-if="Invoice.Items.length > 0 && (!existFactura && !existFacturaPg) && infoPedidos.length > 0"
    label="Crear Preliminar"
    icon="pi pi-save"
    class="p-button-raised p-button-success floating-button p-button-rounded"
    @click="onSubmit"
  />

  <ModalAsignarLotes ref="modalAsignarLotes" />
  <SidebarItemsPedido
    ref="openSidebarItemsPedido"
    @emitUpdateLineFactura="updateLineFactura"
    @emitCancelLineFactura="cancelLineFactura"
  />
  <ModalValidation ref="modalValidation" @emitOnSubmitPreliminar="onSubmitSavePreliminar" />

  <ModalCargarLotesDisfarma ref="modalCargueLotesDisfarma" />
</template>

<style scoped>
::v-deep(.p-column-header-content) {
    justify-content: center;
}

.drop-zone {
    width: 400px;
    height: 200px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 18px;
    color: #888;
    line-height: 150px;
    margin: 0 auto;
}

.floating-button {
    position: fixed !important;
    bottom: 35px;
    right: 40px;
    z-index: 1050;
}

.drop-zone.hover {
    border-color: #aaa;
    background: #f8f8f8;
    color: #555;
}

.p-custom-card {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19);
}

.custom-scrollbar ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 5px;
}

.custom-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes highlight {
  0% {background-color: #ffff99;}
  100% {background-color: transparent;}
}

.highlight {
  animation-name: highlight;
  animation-duration: 3s;
}

.highlight_pedido {
  background-color: #99ff9b;
}

@keyframes highlight_copy {
  0% {background-color: #5fd0aa;}
  100% {background-color: transparent;}
}

.highlight_copy {
  animation-name: highlight_copy;
  animation-duration: 3s;
}
</style>
