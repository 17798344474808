<script setup>
import { defineEmits, defineExpose, ref } from 'vue'

const displayModal = ref(false)
const dataValidation = ref([])
const expandedRows = ref([])
const emit = defineEmits(['emitOnSubmitPreliminar'])

// Expandir y contraer filas
const onRowExpand = (event) => {
  console.log('onRowExpand', event)
}
const onRowCollapse = (event) => {
  console.log('onRowCollapse', event)
}

//
const setColorQtyFont = (data) => {
  return {
    'text-red-600': parseInt(data.QuantityAsignado) !== parseInt(data.QuantityWithConversion),
    'text-green-600': parseInt(data.QuantityAsignado) === parseInt(data.QuantityWithConversion)
  }
}

//
const setColorTotalLineFont = (data) => {
  const roundedTotalLine = Math.round(data.TotalLine)
  const roundedTotalLineCalculadoFactura = Math.round(data.TotalLineCalculadoFactura)

  return {
    'text-red-600': roundedTotalLineCalculadoFactura !== roundedTotalLine,
    'text-green-600': roundedTotalLineCalculadoFactura === roundedTotalLine
  }
}

//
const setColorQtyWithoutConversionFont = (data) => {
  return {
    'text-red-600': parseInt(data.QuantityAsignado) !== parseInt(data.Quantity),
    'text-green-600': parseInt(data.QuantityAsignado) === parseInt(data.Quantity)
  }
}

// Funcion para abrir el modal
const openModal = async (data) => {
  displayModal.value = true
  dataValidation.value = data
}

// Funcion para cerrar el modal
const closeModal = async () => {
  displayModal.value = false
  // Reiniciar expandedRows y collapsedRows
  expandedRows.value = []
}

// Emit
const onSubmitPreliminar = async () => {
  emit('emitOnSubmitPreliminar', true)
}

defineExpose({
  openModal
})

</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    modal
    maximizable
    dismissableMask
    @hide="closeModal"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '70vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div :class="`flex items-center text-xl font-bold text-${!dataValidation.isValidatePreliminar ? 'red' : 'green'}-600`">
        <i :class="`pi pi-${!dataValidation.isValidatePreliminar ? 'exclamation-triangle' : 'check'} mr-2 text-xl`"></i>
        <h4>{{ !dataValidation.isValidatePreliminar ? `Validación pendiente` : `Validación Exitosa` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="card flex flex-col">
      <div class="flex flex-row flex-wrap justify-between">
        <div class="flex flex-col">
          <p class="text-base mb-4 font-semibold">
            El valor total de las lineas
            <span :class="`text-lg text-${!dataValidation.isValidatePreliminar ? 'red' : 'green'}-700 font-bold p-1 bg-${!dataValidation.isValidatePreliminar ? 'red' : 'green'}-200 rounded`">{{ $h.formatCurrency(dataValidation.totalLineas, 2) }}</span>
            {{ !dataValidation.isValidatePreliminar ? 'debe ser ' : 'es' }} igual al valor total de la factura
            <span class="text-lg text-green-700 font-bold p-1 bg-green-200 rounded">{{ $h.formatCurrency(dataValidation.docTotal, 2) }}</span>
          </p>

          <p class="mb-4">A continuación se visualiza el detalle de la información asociada a la factura:</p>
        </div>
        <div class="flex flex-col">
          <span style="text-align: end"><strong>Fecha factura:</strong> {{dataValidation.DocDate}}</span>
          <span style="text-align: end"><strong>Fecha recibido:</strong> {{dataValidation.TaxDate}}</span>
        </div>
      </div>

      <DataTable
        :value="dataValidation.items"
        tableStyle="min-width: 50rem"
        class="p-datatable-sm text-xs"
        sortField="Description"
        :sortOrder="1"
        v-model:expandedRows="expandedRows"
        @rowExpand="onRowExpand"
        @rowCollapse="onRowCollapse"
        dataKey="CodCum"
      >
        <template #empty> No hay datos para mostrar. </template>
        <template #loading> Cargando la información, por favor espere... </template>
        <Column
          expander
          style="width: 3rem"
          :pt="{
            rowToggler: {
              style: 'width: 1rem; height: 1rem;'
            }
          }"
        />
        <Column field="Description" header="Descripción" :sortable="true" style="max-width: 25rem;">
          <template #body="{data}">
            <div class="flex flex-col flex-wrap">
              <div class="flex flex-row flex-wrap justify-between">
                <span>{{ data.Description }}</span>
                <Badge
                  v-if="data.IsSustituto"
                  :value="`Sustituto`"
                  style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                  class="whitespace-nowrap bg-yellow-300 text-yellow-800 capitalize cursor-pointer font-bold"
                />
              </div>
              <span class="text-xxs" v-if="data.IsSustituto">En pedido: {{ data.ItemNamePedido }}</span>
            </div>
          </template>
        </Column>
        <Column field="CodCum" sortable header="Cod. Cum">
          <template #body="{data}">
            <div class="flex flex-col">
              <span>{{ data.CodCum }}</span>
              <span class="text-xxs" v-if="data.IsSustituto">En pedido: {{ data.CodCumPedido }}</span>
            </div>
          </template>
        </Column>
        <Column field="Quantity" sortable header="Cant.Fact">
          <template #body="{data}">
            <div class="flex flex-col">
              <span>{{ data.IsUnique ? data.Quantity : data.QuantityRepeat }}</span>
            </div>
          </template>
        </Column>
        <Column
          v-if="!dataValidation.noApplyConversion"
          field=""
          header="Conversión"
        >
          <template #body="{data}">
            <div class="flex flex-col">
              <span>{{ data.Conversion }}</span>
            </div>
          </template>
        </Column>
        <Column
          v-if="!dataValidation.noApplyConversion"
          field=""
          header="Cant.Fact x Conversión"
          style="max-width: 3rem;"
          :pt="{
            bodyCell: {
              style: 'background: rgba(255, 242, 204, 0.5);'
            }
          }"
        >
          <template #body="{data}">
            <div class="flex flex-col">
              <span>{{ data.IsUnique ? data.QuantityWithConversion : data.QuantityWithConversionRepeat }}</span>
            </div>
          </template>
        </Column>
        <Column
          field=""
          header="Cant. Asignados"
          style="max-width: 3rem;"
          :pt="{
            bodyCell: {
              style: 'background: rgba(255, 242, 204, 0.5);'
            }
          }"
        >
          <template #body="{data}">
            <div class="flex flex-col">
              <span class="font-bold" :class="dataValidation.noApplyConversion ? setColorQtyWithoutConversionFont(data) : setColorQtyFont(data)">{{ data.QuantityAsignado }}</span>
            </div>
          </template>
        </Column>
        <Column
          field=""
          header="Iva"
        >
          <template #body="{data}">
            <div class="flex flex-col">
              <span>{{ data.TaxPercent }}%</span>
            </div>
          </template>
        </Column>
        <Column
          field=""
          header="Total Factura"
          style="max-width: 4rem;"
          :pt="{
            bodyCell: {
              style: 'background: rgba(221, 235, 247, 0.5);'
            }
          }"
        >
          <template #body="{data}">
            <div class="flex flex-col">
              <span>{{ data.IsUnique ? $h.formatCurrency(parseFloat(data.TotalLine)) : $h.formatCurrency(parseFloat(data.TotalLineRepeat)) }}</span>
            </div>
          </template>
        </Column>
        <Column
          field="TotalLine"
          header="Total Asignado"
          style="max-width: 4rem;"
          :pt="{
            bodyCell: {
              style: 'background: rgba(221, 235, 247, 0.5);'
            }
          }"
        >
          <template #body="{data}">
            <div class="flex flex-col">
              <span class="font-bold" :class="setColorTotalLineFont(data)">{{ $h.formatCurrency(parseFloat(data.TotalLineCalculadoFactura)) }}</span>
            </div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <h5 class="text-sm font-semibold text-gray-600 mb-2">Información de los artículos</h5>
          <DataTable
            :value="slotProps.data.Items"
            showGridlines
            responsiveLayout="scroll"
            class="p-datatable-sm text-xs"
          >
            <Column field="IndexFact" header="Línea" />
            <Column field="NomMxAsignado" header="Descripción">
              <template #body="{data}">
                <div class="flex flex-col">
                  <div class="flex flex-row flex-wrap justify-between">
                    <span>{{ data.NomMxAsignado }}</span>
                    <Badge
                      v-if="data.IsSustituto"
                      :value="`Sustituto`"
                      style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                      class="whitespace-nowrap bg-yellow-300 text-yellow-800 capitalize cursor-pointer font-bold"
                    />
                  </div>
                  <span class="text-xxs" v-if="data.IsSustituto">En pedido: {{ data.ItemNamePedido }}</span>
                </div>
              </template>
            </Column>
            <Column field="BodegaMxAsignado" header="Bodega" />
            <Column field="CumMxAsignado" header="Cod. Cum">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.CumMxAsignado }}</span>
                  <span class="text-xxs" v-if="data.IsSustituto">En pedido: {{ data.CodCumPedido }}</span>
                </div>
              </template>
            </Column>
            <Column field="ConversionMxAsignado" header="Conversión" v-if="!dataValidation.noApplyConversion" />
            <Column field="InvoiceQuantity" header="Cantidad Asignada" />
            <Column field="TotalLineCalculado" header="Total Linea">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ $h.formatCurrency(parseFloat(data.TotalLineCalculado)) }}</span>
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </DataTable>

      <!-- <pre class="text-xs" style="line-height: 0.85rem">{{ JSON.stringify(dataValidation, null, '\t') }}</pre> -->

      <p class="mt-4 text-xs text-gray-600 underline" v-if="!dataValidation.isValidatePreliminar">Por favor, asigne el valor correcto a los artículos e intente de nuevo.</p>
      <p class="mt-4 text-xs text-gray-600 underline" v-else>La validación se realizó de forma correcta, por favor de clic en el botón de Guardar</p>
    </div>

    <template #footer>
      <Button
        v-if="!dataValidation.isValidatePreliminar"
        label="Intentar de nuevo"
        @click="closeModal"
        severity="danger"
        class="p-button-xs"
        autofocus
      />
      <Button
        v-else
        label="Guardar"
        severity="success"
        @click="onSubmitPreliminar"
        class="p-button-xs p-button-success"
      />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
